import { Icons } from '@model/common/icons';
import { LONG_MONTH_YEAR_FORMAT } from '@model/common';
import React from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { Icon } from '@components/common/generic-icon';
import moment from 'moment';
import { TestId } from '@components/test-ids';

const Container = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.custom.spacing.large
}));

interface StyledChevronProps extends ThemeProps {
  disabled: boolean;
}
const StyledChevron = styled<any>(Icon)(({ theme, disabled }: StyledChevronProps) => ({
  fontSize: '1.4rem',
  color: theme.custom.colors.group4.base,
  cursor: 'pointer',
  padding: theme.custom.spacing.small,
  margin: -theme.custom.spacing.small,
  ':hover': {
    color: theme.custom.colors.group7.base,
    ...(disabled && { color: theme.custom.colors.group4.lighter, cursor: 'default' })
  },
  ...(disabled && { color: theme.custom.colors.group4.lighter, cursor: 'default' })
}));

const Year = styled.div<any>(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500
}));

export interface NavigationProps {
  month: moment.Moment;
  onPrev: () => void;
  onNext: () => void;
}

export const Navigation = ({ month, onPrev, onNext }: NavigationProps) => {
  const canNavigateBackwards: boolean = month.clone().subtract(1, 'month').isSameOrAfter(moment(), 'month');
  const canNavigateForwards: boolean = month.clone().add(1, 'month').isSameOrBefore(moment().add(2, 'years'), 'month');
  return (
    <Container>
      <StyledChevron name={Icons.CHEVRON_LEFT} disabled={!canNavigateBackwards} onClick={onPrev} />
      <Year data-testid={TestId.dealFinder.dayPicker.month}>{month.format(LONG_MONTH_YEAR_FORMAT)}</Year>
      <StyledChevron name={Icons.CHEVRON_RIGHT} disabled={!canNavigateForwards} onClick={onNext} />
    </Container>
  );
};
