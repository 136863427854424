import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { uniq, isNull, pull, map, flatMap } from 'lodash';
import { TestId } from '@components/test-ids';
import { SearchButton } from '@components/search/tabs/common/search-button';
import { OccupancyContainer } from '@components/search/occupancy/OccupancyContainer';
import { MaxContentWidth } from '@components/common/layout';
import { TourDestinationsContainer } from './destinations/TourDestinationsContainer';
import { Theme } from '@theme/base';
import { useTheme } from '@emotion/react';
import {
  getSearchParams,
  getToursByType,
  setSearchParams,
  setTourByType,
  unsetTourByType,
  clearToursByType
} from '@state/search/tours';
import { setToursQueryByType } from '@state/algolia/algoliaOperations';
import { Occupancy } from '@model/state/deal-finder-state';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { TripTypes } from '@model/common/tours/trip-types';
import { TourSearchParams } from '@model/state/search-forms-state';
import { FILTER_DEFAULTS } from '@model/price-calendar';
import { AirportsContainer } from '@components/search/airports/AirportsContainer';
import { DatesContainer } from '@components/search/dates/DatesContainer';

export interface TourSearchProps {
  tripType: TripTypes;
  isCondensed: boolean;
  isVisible: boolean;
  onClick: () => void;
}

export interface setTourSearchParamParams {
  field: keyof TourSearchParams;
  value: number | string | Array<string> | Array<Occupancy> | null;
}

export const TourSearch = ({ tripType, isCondensed, isVisible, onClick }: TourSearchProps) => {
  const theme: Theme = useTheme();
  const ref: any = useRef();
  const dispatch = useDispatch();
  const searchData = useSelector(getSearchParams);
  const selectedTours = useSelector(getToursByType(tripType));
  const searchMonth = searchData.month || [];
  const searchAirports = searchData.airports || [];
  const searchOccupancy = searchData.occupancy || FILTER_DEFAULTS.occupancy;
  const availableAirports = flatMap(selectedTours, ({ airports }) => map(airports, 'code'));

  const setTourSearchParam = ({ field, value }: setTourSearchParamParams): void => {
    dispatch(setSearchParams({ [field]: value }));
  };
  const setAirports = (airports: Array<string> | null) => {
    setTourSearchParam({ field: 'airports', value: airports });
  };
  const setDate = (date: string | null) => {
    const value = !isNull(date)
      ? searchMonth.includes(date)
        ? pull(searchMonth, date)
        : uniq(searchMonth.concat([date]))
      : null;
    setTourSearchParam({ field: 'month', value });
  };
  const setOccupancy = (occupancy: Array<Occupancy> | null) => {
    setTourSearchParam({ field: 'occupancy', value: occupancy });
  };
  const noop = () => {};
  const handleOnTourSelect = (tour: TourHit) => {
    dispatch(
      setTourByType({
        tour,
        tripType
      })
    );
    dispatch(setToursQueryByType({ tripType, query: '' }));
  };
  const handleOnTourRemove = (tour: TourHit) => {
    dispatch(
      unsetTourByType({
        tour,
        tripType
      })
    );
  };
  const handleOnToursClear = () => {
    dispatch(clearToursByType(tripType));
  };

  return (
    <MaxContentWidth testId={TestId.searchContainer}>
      <Grid container={true} spacing={theme.custom.gridSpacing.small} ref={ref}>
        <Grid item={true} xs={12} sm={6} lg={4}>
          <TourDestinationsContainer
            tabsRef={ref}
            isCondensed={isCondensed}
            onAdd={handleOnTourSelect}
            onRemove={handleOnTourRemove}
            onClear={handleOnToursClear}
            tripType={tripType}
            isVisible={isVisible}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} lg={3}>
          <AirportsContainer
            tabsRef={ref}
            isCondensed={isCondensed}
            onChange={setAirports}
            onClear={() => setAirports(null)}
            isNotGroupResults
            airports={searchAirports}
            available={availableAirports}
            placeholderKey={'common__label--all-airports'}
            tripType={tripType}
            isVisible={isVisible}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} lg={2}>
          <DatesContainer
            tabsRef={ref}
            isCondensed={isCondensed}
            onMonthChange={setDate}
            onClear={() => setDate(null)}
            isMonthOnly
            month={searchMonth}
            placeholderKey={'common__label--any-date'}
          />
        </Grid>
        <Grid item={true} xs={12} sm={6} lg={2}>
          <OccupancyContainer
            tabsRef={ref}
            isCondensed={isCondensed}
            adultsOnly
            maxRooms={1}
            maxGuestsPerRoom={3}
            occupancy={searchOccupancy}
            onChange={setOccupancy}
            onClear={() => setOccupancy(null)}
            onBlur={noop}
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} lg={1}>
          <SearchButton onClick={onClick} condensed={isCondensed} />
        </Grid>
      </Grid>
    </MaxContentWidth>
  );
};
