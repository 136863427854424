import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { Board } from '@model/iceberg/service/price-calendar';
import { StyledMenuItem } from '@components/price-calendar/filters/styles';

const Container = styled.div({
  width: '100%',
  [mq.medium]: {
    height: 'auto'
  }
});

export interface BoardProps {
  board: string;
  boards: Array<Board>;
  onChange: (board: string) => void;
}

export const Boards = ({ board, boards, onChange }: BoardProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleOnChange = (payload: string) => {
    setIsActive(false);
    onChange(payload);
  };
  const selectedBoard: Board | undefined = boards.find((boardBasis: Board) => boardBasis.code === board);
  const value: string = selectedBoard?.name || '';
  return (
    <SearchContainer
      tabsRef={null}
      title={''}
      icon={Icons.MEAL}
      isCondensed
      isClearable={false}
      onClear={() => {}}
      isActive={isActive}
      onSetActive={setIsActive}
      isError={false}
      hasGradient={false}
      hideConfirm
      renderValue={() => value}
      renderBody={() => (
        <Container data-testid={TestId.dealFinder.duration}>
          {boards.map((item: Board) => (
            <StyledMenuItem
              key={item.code}
              value={item.code}
              onClick={() => handleOnChange(item.code)}
              selected={item.code === board}
            >
              {item.name}
            </StyledMenuItem>
          ))}
        </Container>
      )}
    />
  );
};
