import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import React from 'react';
import { KeyCodes, TabIndex } from '@model/common';
import { TestId } from '@components/test-ids';

export interface PillProps {
  value: string | number;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  className?: string;
}

interface ContainerProps extends ThemeProps {
  selected: boolean;
  disabled: boolean;
}
const Container = styled.div<any>(({ theme, selected, disabled }: ContainerProps) => ({
  borderRadius: 4,
  padding: theme.custom.spacing.small,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.custom.colors.group10.light}`,
  ...(theme.custom.typography.labels.default as any),
  cursor: 'pointer',
  outline: 'none',
  userSelect: 'none',
  ':hover': {
    border: `1px solid ${theme.custom.colors.group10.light}`,
    backgroundColor: theme.custom.colors.group10.lighter,
    ...(selected && {
      border: `1px solid ${theme.custom.colors.group1.base}`,
      backgroundColor: theme.custom.colors.group1.lighter
    }),
    ...(disabled && {
      border: `1px solid ${theme.custom.colors.group10.light}`,
      backgroundColor: theme.custom.colors.white,
      color: theme.custom.colors.group4.lighter,
      cursor: 'default'
    })
  },
  ...(selected && {
    border: `1px solid ${theme.custom.colors.group1.base}`,
    backgroundColor: theme.custom.colors.group1.lighter
  }),
  ...(disabled && {
    border: `1px solid ${theme.custom.colors.group10.light}`,
    backgroundColor: theme.custom.colors.white,
    color: theme.custom.colors.group4.lighter,
    cursor: 'default'
  })
}));

export const Pill = ({ value, selected, onClick, disabled, className }: PillProps) => {
  const handleOnKeyDown = (e: any) => {
    if (!disabled && e.keyCode === KeyCodes.ENTER) {
      onClick();
    }
  };
  const handleOnClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <Container
      className={className}
      selected={selected}
      disabled={disabled}
      tabIndex={TabIndex.ZERO}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      data-testid={TestId.dealFinder.pill}
    >
      {value}
    </Container>
  );
};
