import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { ChildDOB } from '@model/search';
import moment from 'moment';
import React from 'react';
import { useI18NextContext } from '@components/hooks';
import { Child } from '@model/guests/child';
import { DOB } from '@model/state';
import { ThemeProps } from '@theme/base';

export interface AgeLabelProps {
  value: DOB;
}

export const PLACE_HOLDER: string = '-';
const MAX_AGE: number = 150;
const MIN_AGE: number = 0;

export const getAgeLabel = (value: ChildDOB, t: any) => {
  const child: Child = new Child(value.day, value.month, value.year);
  const age: number = child.age;
  const dob: moment.Moment = child.moment;
  if (!value || child.isEmpty) return PLACE_HOLDER;
  if (age <= MIN_AGE) {
    const duration = moment.duration(moment().diff(dob));
    const months: number = duration.months();
    const days: number = duration.days();
    if (months > 0) {
      return `${months} ${t('common__months--label')}`;
    } else if (days > 0) {
      if (days === 1) {
        return `${days} ${t('common__day--label')}`;
      }
      return `${days} ${t('common__days--label').toLowerCase()}`;
    }
  }
  const isDateValid: boolean = dob.isValid();
  const invalidAge: boolean = age > MAX_AGE || age <= MIN_AGE;
  const invalid: boolean = !isDateValid || invalidAge;
  return invalid ? PLACE_HOLDER : age.toString();
};

const Container = styled.div(({ theme }: ThemeProps) => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(theme.custom.typography.labels.default as any),
  fontWeight: 'bold'
}));

export const AgeLabel = (props: AgeLabelProps) => {
  const t: any = useI18NextContext();
  return <Container data-testid={TestId.dob.ageLabel}>{getAgeLabel(props.value, t)}</Container>;
};
