import React from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { CheckboxComponent } from '@components/common/checkbox';
import { DayPicker } from '@components/search/dates/calendar/day-picker/DayPicker';

const Container = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  padding: theme.custom.spacing.medium
}));

const DatePickerContainer = styled.div({
  width: '100%'
});

const StyledCheckbox = styled(CheckboxComponent)(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.custom.spacing.medium
}));

export interface CalendarProps {
  date: string;
  flexibleDays: boolean;
  onChange: (date: string) => void;
  onFlexibleDaysChange: (checked: boolean) => void;
}

export const Calendar = ({ date, flexibleDays, onChange, onFlexibleDaysChange }: CalendarProps) => {
  return (
    <Container>
      <StyledCheckbox
        value={flexibleDays}
        checked={flexibleDays}
        onChange={() => onFlexibleDaysChange(!flexibleDays)}
        label={'search__dates--flexible-days'}
        available
      />
      <DatePickerContainer>
        <DayPicker day={date} onChange={onChange} />
      </DatePickerContainer>
    </Container>
  );
};
