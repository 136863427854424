import React from 'react';
import { PriceCalendarFiltersState, PriceCalendarMonthlyState } from '@model/state/price-calendar-state';
import styled from '@emotion/styled';
import { Footer } from './footer';
import { boxShadowMixins } from '@styles/mixins';
import { Nav } from './nav';
import { Calendar } from '@components/price-calendar/calendar/Calendar';
import { FiltersContainer } from '@components/price-calendar/filters/FiltersContainer';
import { useI18NextContext } from '@components/hooks';
import { ThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';
import { formatMonthYear } from '@util/date-time';

export interface PriceCalendarProps {
  filters: PriceCalendarFiltersState;
  monthly: PriceCalendarMonthlyState;
  onDayChange: (day: string) => void;
  onMonthChange: (month: string) => void;
  onSubmit: () => void;
  priceCalendarRef?: any;
  tableOnly?: boolean;
  loading: boolean;
  disabled?: boolean;
  hasUnsaved?: boolean;
  useDefault?: boolean;
  viewWithFlightsSearch?: () => void;
}

const Heading = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large
}));

const Title = styled.h3(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.h3 as any),
  marginBottom: theme.custom.spacing.xSmall,
  marginTop: 0,
  color: theme.custom.colors.group4.base,
  fontWeight: 'bold'
}));

const Subtitle = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  marginBottom: theme.custom.spacing.medium,
  color: theme.custom.colors.group4.base
}));

const Container = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  flexDirection: 'column',
  boxShadow: boxShadowMixins(theme.custom.colors.group10.lighter).lighter,
  border: '1px solid',
  borderColor: theme.custom.colors.group10.light
}));

export const PriceCalendar = ({
  filters,
  monthly,
  onMonthChange,
  onDayChange,
  onSubmit,
  priceCalendarRef,
  tableOnly,
  loading,
  hasUnsaved,
  disabled,
  useDefault,
  viewWithFlightsSearch
}: PriceCalendarProps) => {
  const t: any = useI18NextContext();
  const {
    month,
    date,
    defaultDate,
    response: {
      data: { prices }
    }
  } = monthly;

  const calendarDate = date || (useDefault ? defaultDate : '');

  return (
    <Container ref={priceCalendarRef} id={'price-calendar'} data-testid={TestId.priceCalendar.container}>
      <Heading>
        {!tableOnly && <Title>{t('price-calendar__section--title')}</Title>}
        <Subtitle>{t('price-calendar__section--subtitle')}</Subtitle>
        <FiltersContainer loading={loading} viewWithFlightsSearch={viewWithFlightsSearch} />
      </Heading>
      <Nav date={month} onChange={loading ? () => {} : onMonthChange} />
      <Calendar
        date={calendarDate || formatMonthYear({ month: filters.month, year: filters.year }) || ''}
        month={month}
        prices={prices}
        onChange={onDayChange}
        loading={loading}
        disabled={disabled}
        hasUnsaved={hasUnsaved}
      />
      {!tableOnly && (
        <Footer
          date={calendarDate}
          duration={filters.duration}
          hasPrices={!!prices.length}
          occupancy={filters.occupancy}
          onSubmit={onSubmit}
        />
      )}
    </Container>
  );
};
