import { TestId } from '@components/test-ids';
import { Grid } from '@mui/material';
import React from 'react';
import { AgeLabel } from './age-label/AgeLabel';
import { DOBInput } from './dob-input/DOBInput';
import { Child } from '@model/guests/child';
import { DOB } from '@model/state';

export interface DOBRowProps {
  value: DOB;
  onChange: (date: DOB) => void;
}

export enum DOBFields {
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year'
}

export const DOBRow = (props: DOBRowProps) => {
  const {
    value: { day, month, year },
    value,
    onChange
  } = props;
  const onDOBInput = (input: string, key: string) => onChange({ ...value, [key]: input });
  const child: Child = new Child(day, month, year);
  const hasError: boolean = child.isEmpty || !child.isValid ? false : child.isUnderAge || child.isOverAge;
  return (
    <Grid container={true} spacing={1} data-testid={TestId.dob.row}>
      <Grid item={true} xs={2}>
        <DOBInput
          minLength={0}
          maxLength={2}
          value={day}
          onChange={(input: string) => onDOBInput(input, DOBFields.DAY)}
          error={hasError}
          testId={TestId.dob.day}
        />
      </Grid>
      <Grid item={true} xs={2}>
        <DOBInput
          minLength={0}
          maxLength={2}
          value={month}
          onChange={(input: string) => onDOBInput(input, DOBFields.MONTH)}
          error={hasError}
          testId={TestId.dob.month}
        />
      </Grid>
      <Grid item={true} xs={4}>
        <DOBInput
          minLength={0}
          maxLength={4}
          value={year}
          onChange={(input: string) => onDOBInput(input, DOBFields.YEAR)}
          error={hasError}
          testId={TestId.dob.year}
        />
      </Grid>
      <Grid item={true} xs={4}>
        <AgeLabel value={value} />
      </Grid>
    </Grid>
  );
};
