import styled from '@emotion/styled';
import { breakpoints, mq } from '@styles/breakpoints';
import { boxShadowMixins, mixins } from '@styles/mixins';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useI18NextContext } from '@components/hooks';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { TabIndex } from '@model/common';
import { ThemeProps } from '@theme/base';
import { ZIndex } from '@styles/z-index';
import { ClickAwayListener, Modal } from '@mui/material';
import { useMedia } from 'react-use';
import { Button, ButtonColor, ButtonSize } from '@components/material-ui';
import { TestId } from '@components/test-ids';

const FULL_HEIGHT = 80;
const CONDENSED_HEIGHT = 40;
const MOBILE_HEIGHT = 50;

export const ErrorLabel = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.states.error.dark,
  marginTop: theme.custom.spacing.xSmall,
  ...(theme.custom.typography.labels.default as any)
}));

const Container = styled.div({
  position: 'relative'
});

interface SearchBarProps extends ThemeProps {
  isCondensed: boolean;
  isDisabled?: boolean;
}
const SearchBar: any = styled.div(({ theme, isCondensed, isDisabled }: SearchBarProps) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  borderRadius: 4,
  flex: '1 0 auto',
  backgroundColor: theme.custom.colors.white,
  cursor: isDisabled ? 'default' : 'pointer',
  outline: 'none',
  border: '1px solid',
  borderColor: theme.custom.colors.group10.base,
  boxShadow: 'none',
  height: MOBILE_HEIGHT,
  [mq.large]: {
    border: `1px solid ${theme.custom.colors.group10.base}`,
    height: isCondensed ? CONDENSED_HEIGHT : FULL_HEIGHT
  },
  ...(isDisabled && {
    opacity: 0.75,
    color: theme.custom.colors.group4.base
  }),
  ...(!isDisabled && {
    '&:focus': Active({ theme }),
    '&.isActive': Active({ theme }),
    ':hover': Hover({ theme }),
    '&.isError': Error({ theme })
  })
}));

export const Active = ({ theme }: ThemeProps) => ({
  border: `1px solid ${theme.custom.colors.group7.base}`
});

export const Error = ({ theme }: ThemeProps) => ({
  borderColor: theme.custom.states.error.dark,
  [StyledIcon as any]: {
    color: theme.custom.states.error.dark
  },
  [Chevron as any]: {
    color: theme.custom.states.error.dark
  }
});

export const Hover = ({ theme }: ThemeProps) => ({
  border: `1px solid ${theme.custom.colors.group7.base}`
});

const ValueContainer = styled.div(({ theme }: ThemeProps) => ({
  marginLeft: theme.custom.spacing.xxSmall,
  width: '100%',
  overflow: 'hidden',
  paddingRight: theme.custom.spacing.xLarge,
  [mq.large]: {
    marginLeft: 0
  }
}));

export const Title = styled.h4(({ theme }: ThemeProps) => ({
  display: 'none',
  [mq.large]: {
    textTransform: 'uppercase',
    display: 'block',
    margin: 0,
    padding: 0,
    ...(theme.custom.typography.labels.small as any),
    fontWeight: 500
  }
}));

const Value = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.responsive as any),
  fontWeight: 500,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  ['> div']: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const StyledIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '2rem',
  paddingLeft: theme.custom.spacing.small,
  paddingRight: theme.custom.spacing.small,
  [mq.small]: {
    paddingLeft: theme.custom.spacing.medium,
    paddingRight: theme.custom.spacing.medium
  }
}));

const Chevron = styled(Icon)(({ theme }: ThemeProps) => ({
  position: 'absolute',
  right: theme.custom.spacing.medium
}));

interface ContainerProps extends ThemeProps {
  isCondensed: boolean;
  zIndex: number;
}
const DropdownContainer: any = styled.div(({ theme, isCondensed, zIndex }: ContainerProps) => ({
  height: '100%',
  width: '100vw',
  position: 'absolute',
  borderRadius: 2,
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).glow,
  zIndex: zIndex || ZIndex.SEARCH_DROPDOWN,
  cursor: 'default',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.custom.colors.white,
  ...(theme.custom.typography.labels.default as any),
  border: `1px solid ${theme.custom.colors.group10.light}`,
  outline: 'none',
  [mq.small]: {
    minWidth: '100%',
    width: 'auto'
  },
  [mq.medium]: {
    // check duration movile
    top: CONDENSED_HEIGHT + 10,
    height: 'auto',
    left: 0
  },
  [mq.large]: {
    top: isCondensed ? CONDENSED_HEIGHT : FULL_HEIGHT
  }
}));

const DropdownConfirmation = styled.div(({ theme }: ThemeProps) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  height: 70,
  ...(theme.custom.typography.paragraph as any),
  ...mixins(theme).link,
  textDecoration: 'none',
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  padding: theme.custom.spacing.medium,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
  [mq.medium]: {
    marginTop: theme.custom.spacing.small,
    position: 'relative',
    height: 50
  }
}));

const Confirm = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  float: 'right'
});

export const DesktopContainer = styled.div({
  display: 'none',
  [mq.medium]: {
    display: 'block'
  }
});

const StyledButton = styled(Button)({
  width: 120,
  float: 'right'
});

const Clear = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  display: 'inline-flex',
  height: '100%',
  alignItems: 'center',
  cursor: 'pointer',
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  transition: `color ${theme.custom.transitions.default}`,
  [StyledClearIcon]: {
    color: theme.custom.colors.group11.base
  },
  ':hover': {
    color: theme.custom.colors.group11.dark,
    [StyledClearIcon]: {
      color: theme.custom.colors.group11.dark
    }
  }
}));

const StyledClearIcon: any = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1rem',
  marginLeft: theme.custom.spacing.xSmall,
  marginTop: 3
}));

interface ScrollProps extends ThemeProps {
  hasGradient: boolean;
  hideConfirm: boolean;
}
const Scroll: any = styled.div(({ theme, hasGradient, hideConfirm }: ScrollProps) => ({
  height: '100%',
  overflow: 'auto',
  marginBottom: hideConfirm ? 0 : 70,
  ...(hasGradient && {
    '&:after': {
      content: '""',
      height: 50,
      left: 0,
      width: '100%',
      background: `linear-gradient(0, ${theme.custom.colors.white}, rgba(255,255,255,0))`,
      position: 'absolute',
      bottom: 70,
      pointerEvents: 'none',
      touchAction: 'none',
      [mq.medium]: {
        position: 'absolute',
        bottom: 58
      }
    }
  }),
  [mq.medium]: {
    maxHeight: 500,
    marginBottom: 0
  }
}));

const getChevron = (active: boolean) => (active ? Icons.CARET_UP : Icons.CARET_DOWN);

export interface SearchContainerProps {
  title: string;
  icon: Icons;
  tabsRef: any;
  isError?: boolean;
  isCondensed: boolean;
  isClearable: boolean;
  isActive: boolean;
  isDisabled?: boolean;
  hideConfirm?: boolean;
  hasGradient?: boolean;
  renderValue: () => JSX.Element | string;
  renderBody: () => JSX.Element | null;
  renderError?: () => JSX.Element;
  renderHeader?: () => JSX.Element | null;
  onClear: () => void;
  clearLabelKey?: string;
  onSetActive: (active: boolean) => void;
  valueTestId?: string;
}

export const SearchContainer = ({
  title,
  icon,
  tabsRef,
  isError,
  isActive,
  isCondensed,
  isClearable,
  isDisabled,
  hideConfirm,
  hasGradient,
  renderValue,
  renderHeader,
  renderBody,
  renderError,
  onClear,
  clearLabelKey,
  onSetActive,
  valueTestId
}: SearchContainerProps) => {
  const t: any = useI18NextContext();
  const isMobile = useMedia(`(max-width: ${breakpoints.medium - 1}px)`);
  const handleOnClickAway = () => {
    if (isActive && !isMobile) {
      onSetActive(false);
    }
  };
  useEffect(() => {
    if (isActive && !isMobile) {
      tabsRef?.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [isActive]);

  return (
    <ClickAwayListener onClickAway={handleOnClickAway}>
      <div data-testid={TestId.dealFinder.search.container}>
        <Container>
          <SearchBar
            data-testid={TestId.dealFinder.search.bar}
            isCondensed={isCondensed}
            isDisabled={isDisabled}
            className={classNames({ isError, isActive })}
            tabIndex={TabIndex.ZERO}
            onClick={isDisabled ? () => null : () => onSetActive(!isActive)}
          >
            <StyledIcon name={icon} />
            <ValueContainer>
              {!isCondensed && <Title>{t(title)}</Title>}
              <Value data-testid={valueTestId || TestId.dealFinder.search.value}>{renderValue()}</Value>
            </ValueContainer>
            {!isDisabled && <Chevron name={getChevron(isActive)} />}
            {isActive && (
              <>
                <DesktopContainer>
                  <DropdownContainer isCondensed={isCondensed} onClick={(e: any) => e.stopPropagation()}>
                    {renderHeader && renderHeader()}
                    <Scroll hasGradient={hasGradient} hideConfirm={hideConfirm}>
                      {renderBody()}
                    </Scroll>
                    {!hideConfirm && (
                      <DropdownConfirmation>
                        <Confirm onClick={() => onSetActive(false)}>{t('common__label--confirm')}</Confirm>
                        {isClearable && (
                          <Clear onClick={onClear}>{t(clearLabelKey || 'search__common--clear-all')}</Clear>
                        )}
                      </DropdownConfirmation>
                    )}
                  </DropdownContainer>
                </DesktopContainer>
                {isMobile && (
                  <Modal open={isActive} onClick={(e: any) => e.stopPropagation()}>
                    <DropdownContainer isCondensed={isCondensed} zIndex={ZIndex.MOBILE_SEARCH}>
                      {renderHeader && renderHeader()}
                      <Scroll hasGradient={hasGradient}>{renderBody()}</Scroll>
                      <DropdownConfirmation>
                        <StyledButton
                          label={t('common__label--confirm')}
                          onClick={() => onSetActive(false)}
                          color={ButtonColor.SECONDARY}
                          size={ButtonSize.SMALL}
                        />
                        {isClearable && <Clear onClick={onClear}>{t('search__common--clear-all')}</Clear>}
                      </DropdownConfirmation>
                    </DropdownContainer>
                  </Modal>
                )}
              </>
            )}
          </SearchBar>
          {isError && <ErrorLabel>{renderError && renderError()}</ErrorLabel>}
        </Container>
      </div>
    </ClickAwayListener>
  );
};
