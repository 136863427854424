import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { TestId } from '@components/test-ids';
import Grid from '@mui/material/Grid';
import { Theme, ThemeProps } from '@theme/base';
import { useTheme } from '@emotion/react';
import { Airports } from '@components/price-calendar/filters/airports/Airports';
import { Boards } from '@components/price-calendar/filters/boards/Boards';
import { Airport, Board } from '@model/iceberg/service/price-calendar';
import { Filters as FiltersState } from '@model/state/price-calendar-state';
import { Occupancy as OccupancyModel } from '@model/state';
import { Occupancy } from '@components/price-calendar/filters/occupancy/Occupancy';
import { Duration } from '@components/price-calendar/filters/duration/Duration';
import { SkeletonElement, SkeletonVariant } from '@components/common/skeleton-loading';
import { getHotelOnlySearch } from '@state/deal-finder/dealFinderSelectors';

const Container = styled(Grid)({ width: '100%', height: '100%' });

const StyledSkeletonElement = styled(SkeletonElement)(({ theme }: ThemeProps) => ({
  border: `1px solid ${theme.custom.colors.group10.light}`,
  padding: `${theme.custom.spacing.small - 2}px ${theme.custom.spacing.medium}px`
}));

export interface FiltersProps {
  airport: string;
  duration: number;
  board: string;
  occupancy: Array<OccupancyModel>;
  boards: Array<Board>;
  airports: Array<Airport>;
  durations: Array<number>;
  onChange: (payload: FiltersState) => void;
  loading: boolean;
  disabled?: boolean;
  viewWithFlightsSearch?: () => void;
}

export const Filters = ({
  airport,
  board,
  duration,
  occupancy,
  airports,
  boards,
  durations,
  onChange,
  loading,
  viewWithFlightsSearch
}: FiltersProps) => {
  const theme: Theme = useTheme();
  const filters: FiltersState = {
    airport,
    board,
    duration,
    occupancy
  };
  const isHotelOnly = useSelector(getHotelOnlySearch);
  const enableOccupancy = durations.length > 0 && (isHotelOnly || airports.length > 0) && boards.length > 0;

  return (
    <Container data-testid={TestId.priceCalendar.filters} container spacing={theme?.custom.gridSpacing.small}>
      <Grid item xs={12} md={6}>
        {(loading || durations.length === 0) && (
          <StyledSkeletonElement variant={SkeletonVariant.RECTANGLE} width={'100%'} height={22} />
        )}
        {!loading && durations.length > 0 && (
          <Duration
            duration={Number(duration)}
            durations={durations}
            onChange={(payload: number) =>
              onChange({
                ...filters,
                duration: payload
              })
            }
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {(loading || (!isHotelOnly && airports?.length === 0)) && (
          <StyledSkeletonElement variant={SkeletonVariant.RECTANGLE} width={'100%'} height={22} />
        )}
        {!loading && (isHotelOnly || airports?.length > 0) && (
          <Airports
            airport={airport}
            airports={airports}
            onChange={(payload: string) =>
              onChange({
                ...filters,
                airport: payload
              })
            }
            disabled={isHotelOnly}
            viewWithFlightsSearch={viewWithFlightsSearch}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {(loading || boards.length === 0) && (
          <StyledSkeletonElement variant={SkeletonVariant.RECTANGLE} width={'100%'} height={22} />
        )}
        {!loading && boards.length > 0 && (
          <Boards
            board={board}
            boards={boards}
            onChange={(payload: string) =>
              onChange({
                ...filters,
                board: payload
              })
            }
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {(loading || !enableOccupancy) && (
          <StyledSkeletonElement variant={SkeletonVariant.RECTANGLE} width={'100%'} height={22} />
        )}
        {!loading && enableOccupancy && (
          <Occupancy
            isDeferred
            occupancy={occupancy}
            onChange={(payload: Array<OccupancyModel>) =>
              onChange({
                ...filters,
                occupancy: payload
              })
            }
          />
        )}
      </Grid>
    </Container>
  );
};
