import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { isTablet, mq } from '@styles/breakpoints';
import AutosizeInput from 'react-input-autosize';
import { Pill } from '@components/search/destinations/suggested-destinations/Pill';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { Name } from '@model/iceberg';
import { Destination } from '@model/state';
import { isHotelPath } from '@util/path';
import { MAXIMUM_DESTINATIONS } from '@model/price-calendar';

const SelectionContainer: any = styled.div(({ theme }: ThemeProps) => ({
  paddingLeft: theme.custom.spacing.medium,
  paddingRight: theme.custom.spacing.medium,
  paddingTop: theme.custom.spacing.medium,
  [mq.medium]: {
    width: '100%'
  }
}));

const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 0%',
  flexWrap: 'wrap'
});

const StyledInput = styled(AutosizeInput)(({ theme }: ThemeProps) => ({
  input: {
    outline: 'none',
    border: 'none',
    height: 30,
    padding: 0,
    margin: 2,
    ...(theme.custom.typography.paragraph as any),
    '::placeholder': {
      ...(theme.custom.typography.paragraph as any)
    }
  }
}));

const StyledPill = styled(Pill)({
  margin: 2
});

const Separator: any = styled.hr(({ theme }: ThemeProps) => ({
  border: 'none',
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  margin: `${theme.custom.spacing.medium}px -${theme.custom.spacing.medium}px ${theme.custom.spacing.medium}px`,
  marginBottom: 0
}));

const Error: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.small
}));

export interface HeadingProps {
  input: string;
  values: Array<Destination>;
  hasMaximumDestinations: boolean;
  onChange: (payload: string) => void;
  onRemove: (payload: Destination) => void;
}

const getPlaceholder = (values: Array<Destination>) => {
  if (values.length) {
    if (values.length === 1) {
      if (isHotelPath(values[0].path)) {
        return '';
      }
    }
    if (values.length === MAXIMUM_DESTINATIONS) {
      return '';
    }
    return 'search__destination--add-another';
  }
  return 'search__destination--enter-destination';
};

export const Heading = ({ input, values, hasMaximumDestinations, onChange, onRemove }: HeadingProps) => {
  const ref: any = useRef();
  const t: any = useI18NextContext();
  const handleOnChange = (event: any) => onChange(event.target.value);
  const inputPlaceholder: string = getPlaceholder(values);
  const isHotelSearch: boolean = values.length === 1 && isHotelPath(values[0].path);
  useEffect(() => {
    if (!isTablet()) {
      ref.current?.focus();
    }
  }, [values]);
  return (
    <SelectionContainer data-testid={TestId.dealFinder.destination.heading}>
      {hasMaximumDestinations && <Error>{t('search__destination--maximum')}</Error>}
      <InputContainer onClick={() => ref.current?.focus()}>
        {values.map((option: Name) => (
          <StyledPill key={option.display} display={option.display} value={option} onClear={onRemove} />
        ))}
        {!isHotelSearch && (
          <StyledInput ref={ref} value={input} onChange={handleOnChange} placeholder={t(inputPlaceholder)} />
        )}
      </InputContainer>
      <Separator />
    </SelectionContainer>
  );
};
