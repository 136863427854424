import styled from '@emotion/styled';
import moment from 'moment';
import { MONTH_YEAR_FORMAT, YEAR_MONTH_FORMAT } from '@model/common';
import React from 'react';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';

const Container = styled.div();

export const DateLabel: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  textTransform: 'none',
  [mq.medium]: {
    ...(theme.custom.typography.paragraph as any)
  }
}));

export interface TabContentProps {
  month: string;
}

export const TabContent = ({ month }: TabContentProps) => {
  return (
    <Container>
      <DateLabel>{moment(month, YEAR_MONTH_FORMAT).format(MONTH_YEAR_FORMAT)}</DateLabel>
    </Container>
  );
};
