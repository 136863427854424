import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { ChildDOB } from '@model/search';
import React from 'react';
import { Counter } from './counter/Counter';
import { ThemeProps } from '@theme/base';

export interface CounterGroupProps {
  adults: number;
  children: Array<ChildDOB>;
  canAddGuests: boolean;
  adultsOnly?: boolean;
  onChangeAdults: (adults: number) => void;
  onChangeChildren: (children: Array<ChildDOB>) => void;
}

const Container = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  [StyledCounter]: {
    ':first-of-type': {
      marginRight: theme.custom.spacing.large
    }
  }
}));

const StyledCounter: any = styled(Counter)();

export const EMPTY_CHILD_VALUE: ChildDOB = {
  day: '',
  month: '',
  year: ''
};

export const getUpdatedChildren = (count: number, children: Array<ChildDOB>) => {
  return count > children.length
    ? children.concat(EMPTY_CHILD_VALUE)
    : children.filter((_, index) => index !== children.length - 1);
};

export const CounterGroup = ({
  adults,
  children,
  onChangeAdults,
  onChangeChildren,
  canAddGuests,
  adultsOnly
}: CounterGroupProps) => {
  const childrenCount: number = children.length;
  const onChangeChildrenCount = (count: number) => onChangeChildren(getUpdatedChildren(count, children));
  const onChangeAdultsCount = (count: number) => {
    if (count < 1) return;
    onChangeAdults(count);
  };
  return (
    <Container data-testid={TestId.guestCounterContainer}>
      <StyledCounter
        title={'common__label--adults'}
        subtitle={'search__rooms--adult_subtitle'}
        count={adults}
        testId={TestId.guestInputContainer.adults}
        onChange={onChangeAdultsCount}
        disableIncrement={!canAddGuests}
        disableDecrement={adults === 1}
      />
      {!adultsOnly && (
        <StyledCounter
          title={'common__label--children'}
          subtitle={'search__rooms--children_subtitle'}
          count={childrenCount}
          testId={TestId.guestInputContainer.children}
          onChange={onChangeChildrenCount}
          disableIncrement={!canAddGuests}
        />
      )}
    </Container>
  );
};
