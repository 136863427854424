import React, { forwardRef } from 'react';
import { Button, ButtonSize, ButtonVariant, ButtonColor } from '../../../../material-ui/button/Button';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';

export interface ButtonProps {
  onClick: any;
  condensed?: boolean;
}

const SearchButtonStyled = styled(Button)(({ condensed }: any) => ({
  height: 50,
  [mq.large]: {
    height: condensed ? 40 : 80
  }
}));

export const SearchButton = forwardRef((props: ButtonProps, ref: any) => {
  const { onClick, condensed } = props;
  return (
    <SearchButtonStyled
      condensed={condensed}
      label={'common__label--search'}
      variant={ButtonVariant.CONTAINED}
      color={ButtonColor.PRIMARY}
      rounded
      size={ButtonSize.FLUID}
      onClick={onClick}
      ref={ref}
    />
  );
});
