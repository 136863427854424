import { formatAmpersand } from '@util/format-amp';

export const getTitle = (items: Array<string>, placeholder: string, t: any) => {
  const MAX_DESTINATIONS: number = 1;
  if (Array.isArray(items) && items.length) {
    const label: string = items.slice(0, MAX_DESTINATIONS).join(', ');
    const decodedLabel = formatAmpersand(label);

    if (items.length > MAX_DESTINATIONS) {
      const excess: number = items.length - MAX_DESTINATIONS;
      return `${decodedLabel} + ${excess} ${t('common__more')}`;
    }
    return decodedLabel;
  }
  return t(placeholder);
};
