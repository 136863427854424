import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import { Filters } from '@components/price-calendar/filters/Filters';
import { PriceCalendarFiltersState, Filters as FiltersState } from '@model/state/price-calendar-state';
import { DURATIONS, BOARD_BASES, FILTER_DEFAULTS } from '@model/price-calendar';
import { getPriceCalendarFilters } from '@state/price-calendar/filter/priceCalendarFilterSelectors';
import { setFilters } from '@state/price-calendar/filter/priceCalendarFilterOperations';
import { getAlgoliaStateAirports } from '@state/algolia/algoliaSelectors';
import { getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { DealFinderState } from '@model/state';
import { getBasketMealPlan, getBasketFlight } from '@state/basket/basketSelectors';
import { Flight } from '@model/iceberg/booking/package';
import { BoardBasisSearch } from '@model/common';

interface FiltersContainerProps {
  loading: boolean;
  viewWithFlightsSearch?: () => void;
}

export const FiltersContainer = ({ loading, viewWithFlightsSearch }: FiltersContainerProps) => {
  const [filtersChanged, setFiltersChanged] = useState(false);
  const dispatch = useDispatch();
  const handleOnChange = (payload: FiltersState) => {
    dispatch(setFilters(payload));
    setFiltersChanged(true);
  };
  const filtersState: PriceCalendarFiltersState = useSelector(getPriceCalendarFilters);
  const dealFinderState: DealFinderState = useSelector(getDealFinderState);
  const basketMealPlan: string | undefined = useSelector(getBasketMealPlan);
  const basketFlight: Flight | null = useSelector(getBasketFlight);
  const basketAirport = basketFlight?.outbound[0].departure.code;

  const {
    response: {
      data: { filters: { airports, boards, durations } = {} }
    },
    occupancy,
    airport,
    board,
    duration
  } = filtersState;
  const algoliaAirports = useSelector(getAlgoliaStateAirports());

  const displayAirport = filtersChanged ? airport : basketAirport || airport;
  const filterAirport =
    displayAirport ||
    (dealFinderState.searchType === SearchType.HOTEL_ONLY
      ? ''
      : dealFinderState.airports.length > 0
        ? dealFinderState.airports[0]
        : FILTER_DEFAULTS.airport);
  const filterBoard = board || BoardBasisSearch[basketMealPlan || ''] || FILTER_DEFAULTS.boardBasis;
  const filterDuration = duration || dealFinderState.duration || FILTER_DEFAULTS.duration;
  const filterOccupancy = !filtersChanged && dealFinderState.occupancy ? dealFinderState.occupancy : occupancy;

  return (
    <Filters
      airport={filterAirport}
      airports={airports && airports?.length > 0 ? airports : map(algoliaAirports, 'airport')}
      board={filterBoard}
      boards={boards && boards.length > 0 ? boards : BOARD_BASES}
      duration={filterDuration}
      durations={durations && durations.length > 0 ? durations : DURATIONS}
      occupancy={filterOccupancy}
      onChange={handleOnChange}
      loading={loading}
      viewWithFlightsSearch={viewWithFlightsSearch}
    />
  );
};
