import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import React from 'react';
import { Counter as CounterComponent } from '@components/common/counter';
import { ThemeProps } from '@theme/base';

export interface CounterProps {
  title: string;
  subtitle: string;
  count: number;
  onChange: (count: number) => void;
  testId?: string;
  onBlur?: () => void;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
  className?: string;
}

const Container = styled.div({});

const TitleContainer = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xSmall
}));

const Title = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  color: theme.custom.colors.group4.base
}));

const Subtitle = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  color: theme.custom.colors.group4.light
}));

export const Counter = (props: CounterProps) => {
  const { testId, title, subtitle, onChange, count, onBlur, disableIncrement, disableDecrement, className } = props;
  const t: any = useI18NextContext();
  return (
    <Container data-testid={testId || TestId.guestInput} className={className}>
      <TitleContainer>
        <Title>{t(title)}</Title> <Subtitle>{t(subtitle)}</Subtitle>
      </TitleContainer>
      <CounterComponent
        count={count}
        onChange={onChange}
        onBlur={onBlur}
        disableIncrement={disableIncrement}
        disableDecrement={disableDecrement}
      />
    </Container>
  );
};
