import { getPlaceLabel, PlaceType } from '@model/iceberg/geography';
import React, { useState } from 'react';
import { GeographyResult, TAB_INDEXES } from '@model/search';
import { Icon } from '@components/common/generic-icon';
import styled from '@emotion/styled';
import Highlighter from 'react-highlight-words';
import { Checkbox } from '@styles/material-ui';
import { TestId } from '@components/test-ids';
import { ThemeProps, DisableableThemeProps } from '@theme/base';
import { escapeRegExp } from '@util/string';
import { useSelector } from 'react-redux';
import { getTabIndex } from '@state/search';

const iconMap = {
  [PlaceType.CONTINENT]: 'mhi-map-pin-outline',
  [PlaceType.COUNTRY]: 'mhi-map-pin-outline',
  [PlaceType.REGION]: 'mhi-map-pin-outline',
  [PlaceType.RESORT]: 'mhi-resort',
  [PlaceType.HOTEL]: 'mhi-hotel'
};

const getIcon = (type: PlaceType) => iconMap[type];

const getSubtitle = ({ place, type }: GeographyResult) => {
  if (!place) return null;
  return getPlaceLabel(place, type);
};

export interface OptionProps {
  option: GeographyResult;
  input: string;
  isChecked: boolean;
  airports: Array<string>;
  onClick: (payload: GeographyResult) => void;
  className?: string;
  hideSubtitle?: boolean;
}

interface ContainerProps extends ThemeProps {
  hideSubtitle?: boolean;
}
const Container: any = styled.div<any>(({ theme, hideSubtitle }: ContainerProps) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginBottom: hideSubtitle ? 0 : theme.custom.spacing.medium,
  '&:last-of-type': {
    marginBottom: hideSubtitle ? 0 : theme.custom.spacing.xLarge
  }
}));

const DescriptionContainer = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginRight: theme.custom.spacing.large
}));

const SubtitleWrapper = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  ...(theme.custom.typography.labels.attribute as any),
  fontWeight: 500,
  width: '100%'
}));

const TitleWrapper = styled.div<any>(({ theme, isDisabled }: DisableableThemeProps) => ({
  display: 'inline-block',
  color: isDisabled ? theme.custom.colors.group4.lighter : theme.custom.colors.group4.base,
  strong: {
    color: isDisabled ? theme.custom.colors.group4.lighter : theme.custom.colors.black
  }
}));

const Title = styled<any>(Highlighter)(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  verticalAlign: 'middle',
  strong: {
    fontWeight: 500
  }
}));

const StyledCheckbox = styled(Checkbox)({
  width: 'auto',
  ['&.MuiCheckbox-root']: {
    padding: 8
  },
  '.MuiFormControlLabel-root': {
    margin: 0,
    width: 'auto'
  }
});

const iconStyles = {
  fontSize: 16,
  marginRight: 24
};

export const Option = ({ option, input, airports, onClick, isChecked, className, hideSubtitle }: OptionProps) => {
  const tabIndex = useSelector(getTabIndex);
  const [hover, setHover] = useState(false);
  const isDisabled: boolean =
    tabIndex !== TAB_INDEXES.HOTEL_ONLY && airports.length && option._tags
      ? !airports.some((airport: string) => option._tags.includes(airport))
      : false;
  const isHover: boolean = !isDisabled && hover;
  const showCheckbox: boolean = option.type !== PlaceType.HOTEL;
  const handleOnClick = () => {
    if (!isDisabled) {
      onClick(option);
    }
  };

  return (
    <Container
      onClick={handleOnClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={className}
      data-testid={TestId.dealFinder.destination.option}
      hideSubtitle={hideSubtitle}
    >
      <DescriptionContainer>
        <Icon name={getIcon(option.type)} css={iconStyles} />
        <TitleWrapper isDisabled={isDisabled}>
          <Title searchWords={[escapeRegExp(input)]} textToHighlight={option.name.display} highlightTag={'strong'} />
          {!hideSubtitle && <SubtitleWrapper>{getSubtitle(option)}</SubtitleWrapper>}
        </TitleWrapper>
      </DescriptionContainer>
      {showCheckbox && (
        <StyledCheckbox
          value={option.name.display}
          hover={isHover}
          checked={isChecked}
          available
          onToggle={() => {}}
          disabled={isDisabled}
        />
      )}
    </Container>
  );
};
