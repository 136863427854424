import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import React, { useState } from 'react';
import { Pill } from '@components/search/common/Pill';
import { useI18NextContext } from '@components/hooks';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { DURATIONS } from '@model/price-calendar';
import { RTapMarkdown } from '@components/common/description';

const Container = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  width: '100%',
  [mq.medium]: {
    width: 320,
    height: 'auto'
  }
}));

const PillContainer = styled.div({
  display: 'block'
});

const Title = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.xLarge,
  marginTop: theme.custom.spacing.large,
  [mq.small]: {
    ...(theme.custom.typography.labels.default as any),
    marginBottom: theme.custom.spacing.large,
    marginTop: 0
  }
}));

const StyledPill: any = styled(Pill)(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium,
  display: 'inline-block',
  marginRight: theme.custom.spacing.xSmall,
  width: `calc(50% - ${theme.custom.spacing.xSmall}px)`,
  [mq.small]: {
    width: '32%',
    '&:nth-of-type(3n)': {
      marginRight: 0
    }
  }
}));

const Notice = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginTop: theme.custom.spacing.medium,
  marginBottom: theme.custom.spacing.medium,
  p: {
    display: 'inline-block',
    margin: 0
  }
}));

const Label = styled.span(({ theme }: ThemeProps) => ({
  paddingRight: theme.custom.spacing.xSmall
}));

export interface DurationProps {
  duration: number;
  tabsRef: any;
  isCondensed: boolean;
  onChange: (duration: number) => void;
  phoneNumber?: string;
}

export const Duration = ({ duration, tabsRef, onChange, isCondensed, phoneNumber }: DurationProps) => {
  const t: any = useI18NextContext();
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <SearchContainer
      tabsRef={tabsRef}
      title={'search__duration--title'}
      icon={Icons.NIGHTS}
      isCondensed={isCondensed}
      isClearable={false}
      onClear={() => {}}
      isActive={isActive}
      onSetActive={setIsActive}
      isError={false}
      hasGradient
      renderValue={() => `${duration?.toString()} ${t('common__label--nights')}`}
      renderBody={() => (
        <Container data-testid={TestId.dealFinder.duration}>
          <Title>{t('search__duration--subtitle')}</Title>
          <PillContainer>
            {DURATIONS.map((durationPill: number) => (
              <StyledPill
                key={durationPill}
                selected={durationPill === duration}
                value={`${durationPill.toString()} ${t('common__label--nights')}`}
                onClick={() => onChange(durationPill)}
              />
            ))}
          </PillContainer>
          <Notice>
            <Label>{t('search__duration--notice')}</Label>
            {phoneNumber && <RTapMarkdown description={phoneNumber} />}
          </Notice>
        </Container>
      )}
    />
  );
};
