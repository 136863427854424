import { Icons } from '@model/common/icons';
import React from 'react';
import { Offer } from '@model/iceberg/service/price-calendar';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { boxShadowMixins } from '@styles/mixins';
import { Icon } from '@components/common/generic-icon';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';

export interface NoResultsProps {
  prices: Array<Offer>;
}

const NoResultsContainer = styled.div(({ theme }: ThemeProps) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.custom.colors.white,
  border: '1px solid',
  borderColor: theme.custom.colors.group10.light,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  padding: theme.custom.spacing.medium,
  ...(theme.custom.typography.labels.hero as any),
  fontWeight: 500,
  boxShadow: boxShadowMixins(theme.custom.colors.group10.base).default,
  borderRadius: 2,
  zIndex: 1
}));

const StyledIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '3rem',
  color: theme.custom.colors.group2.base,
  marginRight: theme.custom.spacing.medium
}));

export const NoResults = () => {
  const t = useI18NextContext();
  return (
    <NoResultsContainer data-testid={TestId.noResults.container}>
      <StyledIcon name={Icons.WARNING} />
      {t('price-calendar__no-results--label')}
    </NoResultsContainer>
  );
};
