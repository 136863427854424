import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { mq } from '@styles/breakpoints';
import { Tabs } from '@components/search/dates/tabs/Tabs';
import { Calendar } from '@components/search/dates/calendar/Calendar';
import { Months } from '@components/search/dates/months/Months';
import { useI18NextContext } from '@components/hooks';

const Container = styled.div({
  width: '100%',
  height: '100%',
  [mq.medium]: {
    width: 320,
    height: 'auto'
  }
});

export interface DatesProps {
  value?: string;
  date: string;
  month: string | Array<string>;
  flexibleDays: boolean;
  tabsRef: any;
  onDateChange: (date: string) => void;
  onMonthChange: (month: string) => void;
  onFlexibleDaysChange: (checked: boolean) => void;
  onClear: () => void;
  isError: boolean;
  isCondensed: boolean;
  isMonthOnly?: boolean;
  placeholderKey?: string;
}

export const Dates = ({
  value,
  date,
  month,
  flexibleDays,
  onDateChange,
  onFlexibleDaysChange,
  onMonthChange,
  onClear,
  tabsRef,
  isError,
  isCondensed,
  isMonthOnly,
  placeholderKey
}: DatesProps) => {
  const [tab, setTab] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const t: any = useI18NextContext();
  useEffect(() => {
    if (!isActive) {
      setTab(!month ? 0 : 1);
    }
  }, [isActive, month]);
  const tabItems = [
    ...(!isMonthOnly
      ? [
          <Calendar
            date={date}
            flexibleDays={flexibleDays}
            onChange={onDateChange}
            onFlexibleDaysChange={onFlexibleDaysChange}
            key="calendar"
          />
        ]
      : []),
    <Months month={month} onChange={onMonthChange} key="months" />
  ];

  return (
    <SearchContainer
      tabsRef={tabsRef}
      title={'search__dates--title'}
      icon={Icons.CALENDAR}
      isCondensed={isCondensed}
      isClearable={!!value}
      onClear={onClear}
      isActive={isActive}
      isError={isError}
      onSetActive={setIsActive}
      hasGradient={false}
      renderError={() => <div>{t('search__dates--placeholder')}</div>}
      renderValue={() => (value ? <div>{value}</div> : t(placeholderKey || 'search__dates--placeholder'))}
      renderBody={() => (
        <Container>
          <Tabs tab={tab} onChange={setTab}>
            {tabItems}
          </Tabs>
        </Container>
      )}
    />
  );
};
