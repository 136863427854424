import { Duration } from '@components/search/duration/Duration';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DealFinderState } from '@model/state';
import { getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { setDuration } from '@state/deal-finder/dealFinderOperations';
import { getMainPhoneNumber } from '@state/cms';

export interface DurationContainerProps {
  tabsRef: any;
  isCondensed: boolean;
}

export const DurationContainer = ({ tabsRef, isCondensed }: DurationContainerProps) => {
  const dispatch = useDispatch();
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const handleOnChange = (duration: number) => dispatch(setDuration(duration));
  const mainPhoneNumber: string | undefined = useSelector(getMainPhoneNumber);
  return (
    <Duration
      tabsRef={tabsRef}
      duration={dealFinder.duration}
      onChange={handleOnChange}
      isCondensed={isCondensed}
      phoneNumber={mainPhoneNumber}
    />
  );
};
