import React, { useState, useEffect } from 'react';
import { SearchContainer } from '@components/search/common/container/SearchContainer';
import styled from '@emotion/styled';
import { getTitle } from '@components/search/common/get-title';
import { Icons } from '@model/common/icons';
import { TripTypes } from '@model/common/tours/trip-types';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { useI18NextContext } from '@components/hooks';
import { DestinationList } from './tour-destinations-list/TourDestinationList';
import { Heading } from './Heading';

export interface TourDestinationsProps {
  tabsRef: any;
  input: string;
  options: Array<TourHit>;
  values: Array<TourHit>;
  isLoading: boolean;
  onInputChange: (payload: string) => void;
  isCondensed: boolean;
  onAdd: (tour: TourHit) => void;
  onRemove: (tour: TourHit) => void;
  onClear: () => void;
  tripType?: TripTypes;
  airports?: Array<string> | null;
}

const StyledSearchContainer = styled(SearchContainer)({
  DropdownContainer: {
    width: '100%'
  }
});

export const TourDestinations = ({
  tabsRef,
  input,
  options,
  values,
  isLoading,
  onInputChange,
  onAdd,
  onRemove,
  onClear,
  isCondensed,
  airports,
  tripType
}: TourDestinationsProps) => {
  const t: any = useI18NextContext();
  /* *** STATE *** */
  const [isActive, setIsActive] = useState<boolean>(false);

  /* *** LOGIC  *** */
  const handleOnInputChange = (payload: string) => onInputChange(payload);
  let titleKey = 'tours-search__destination--subtitle';
  let headingPlaceholderKey = 'tours-search__destination--placeholder';
  switch (tripType) {
    case TripTypes.RIVER_CRUISES:
      titleKey = 'tours-search__destination--cruises-subtitle';
      headingPlaceholderKey = 'tours-search__destination--cruises-placeholder';
      break;
    case TripTypes.MULTI_CENTRE:
      titleKey = 'tours-search__destination--multi-centre-subtitle';
      headingPlaceholderKey = 'tours-search__destination--multi-centre-placeholder';
      break;
  }

  const renderValue = () => (
    <div>
      {values.length ? values.map((tour) => tour.name).join(', ') : getTitle([], 'tours-search__destination--title', t)}
    </div>
  );

  const renderBody = () => (
    <DestinationList
      tabsRef={tabsRef}
      values={values}
      options={options}
      airports={airports || []}
      input={input}
      onInputChange={onInputChange}
      onSelect={onAdd}
      onRemove={onRemove}
      isLoading={isLoading}
      columnCount={4}
    />
  );

  const renderHeader = () => {
    return (
      <Heading
        input={input}
        values={values}
        onChange={handleOnInputChange}
        onRemove={onRemove}
        placeholderKey={headingPlaceholderKey}
      />
    );
  };

  useEffect(() => {
    handleOnInputChange('');
  }, []);

  return (
    <StyledSearchContainer
      tabsRef={tabsRef}
      title={titleKey}
      icon={Icons.SEARCH}
      isCondensed={isCondensed}
      isClearable={values?.length > 0}
      onClear={onClear}
      clearLabelKey="search__common--clear"
      onSetActive={setIsActive}
      isActive={isActive}
      renderValue={renderValue}
      renderBody={renderBody}
      renderHeader={renderHeader}
      hasGradient
    />
  );
};
