import styled from '@emotion/styled';
import { KeyCodes, TabIndex } from '@model/common';
import { withTheme } from '@emotion/react';
import React from 'react';
import { Theme, ThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';
import { Icon } from '../generic-icon';

export interface CounterProps {
  count: number;
  onChange: (count: number) => void;
  theme: Theme;
  onBlur?: () => void;
  disableIncrement?: boolean;
  disableDecrement?: boolean;
  borderColor?: string;
  testId?: string;
}

interface ContainerProps extends ThemeProps {
  borderColor: string;
}

const Container: any = styled.div(({ theme, borderColor }: ContainerProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  border: '2px solid',
  borderColor: borderColor || theme.custom.colors.group10.light,
  borderRadius: '5rem',
  height: 40,
  width: 115,
  userSelect: 'none'
}));

const Count = styled.div(({ theme }: ThemeProps) => ({
  flex: 1,
  textAlign: 'center',
  ...(theme.custom.typography.paragraph as any)
}));

const CounterButton: any = styled.button(({ theme }: ThemeProps) => ({
  height: '100%',
  flex: 1,
  padding: 0,
  borderStyle: 'none',
  backgroundColor: theme.custom.colors.white,
  '&:hover,&:focus': {
    outline: 'none'
  },
  '&:focus': {
    backgroundColor: theme.custom.colors.group10.lighter,
    i: {
      color: theme.custom.colors.group4.base
    }
  },
  '&:hover': {
    background: theme.custom.colors.group10.lighter,
    boxShadow: `inset -3px 0px 2px 0px ${theme.custom.colors.group10.light}`,
    i: {
      color: theme.custom.colors.group1.base
    }
  },
  '&:active': {
    i: {
      color: theme.custom.colors.group10.darker
    }
  },
  '&:disabled': {
    backgroundColor: theme.custom.colors.white,
    i: {
      color: theme.custom.colors.group4.lighter
    }
  }
}));

const Decrement: any = styled(CounterButton)(({ theme }: ThemeProps) => ({
  borderRadius: `${theme.custom.spacing.large}px 0 0 ${theme.custom.spacing.large}px`,
  '&:hover,&:focus': {
    boxShadow: `inset -3px 0px 2px 0px ${theme.custom.colors.group10.light}`
  },
  '&:disabled': {
    boxShadow: 'none'
  }
}));

const Increment: any = styled(CounterButton)(({ theme }: ThemeProps) => ({
  borderRadius: `0 ${theme.custom.spacing.large}px ${theme.custom.spacing.large}px 0`,
  '&:hover,&:focus': {
    boxShadow: `inset 3px 0px 2px 0px ${theme.custom.colors.group10.light}`
  },
  '&:disabled': {
    boxShadow: 'none'
  }
}));

const iconStyles: any = (theme: Theme) => ({
  color: theme.custom.colors.group10.darker,
  fontSize: '1.4rem',
  cursor: 'pointer',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px'
});

export const CounterComponent = (props: CounterProps) => {
  const { count, onChange, theme, onBlur, disableDecrement, disableIncrement, borderColor, testId } = props;
  const onIncrement = () => onChange(count + 1);
  const onDecrement = () => onChange(count - 1);
  const handleOnBlur = (event: any) => {
    const { keyCode, which, shiftKey } = event;
    const key = keyCode || which;
    if (key === KeyCodes.TAB) {
      if (shiftKey) return;
      onBlur && onBlur();
    }
  };
  return (
    <Container data-testid={testId || TestId.counter.main} borderColor={borderColor}>
      <Decrement
        disabled={disableDecrement || count === 0}
        tabIndex={TabIndex.ZERO}
        onClick={onDecrement}
        data-testid={TestId.counter.decrementButton}
      >
        <Icon css={iconStyles(theme)} name={'mhi-minus'} testId={TestId.counter.decrement} />
      </Decrement>
      <Count>{count}</Count>
      <Increment
        disabled={disableIncrement}
        tabIndex={TabIndex.ZERO}
        onClick={onIncrement}
        onKeyDown={handleOnBlur}
        data-testid={TestId.counter.incrementButton}
      >
        <Icon css={iconStyles(theme)} name={'mhi-plus'} testId={TestId.counter.increment} />
      </Increment>
    </Container>
  );
};

export const Counter = withTheme(CounterComponent);
