import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PriceCalendar } from './PriceCalendar';
import { PriceCalendarFiltersState, PriceCalendarMonthlyState } from '@model/state/price-calendar-state';
import {
  getPriceCalendarFilters,
  getPriceCalendarOccupancyError
} from '@state/price-calendar/filter/priceCalendarFilterSelectors';
import { getPriceCalendarMonthly } from '@state/price-calendar/monthly/priceCalendarMonthlySelectors';
import { setDay } from '@state/price-calendar/monthly/priceCalendarMonthlyOperations';
import { GetDepAirportsResponse, ProductApi } from '@model/iceberg';
import { performProductSearch } from '@state/deal-finder-results/dealFinderResultsOperations';
import { INITIAL_DEAL_FINDER_STATE, setHotelLandingSearch } from '@state/deal-finder/dealFinderOperations';
import { getSearchType, getHotelOnlySearch } from '@state/deal-finder/dealFinderSelectors';
import { SearchType } from '@model/iceberg/deal-finder/deal-finder';
import { resetSelectedFilters } from '@state/filters';
import { FiltersSideBarType } from '@model/filters';

export interface PriceCalendarContainerProps {
  display: string;
  path: string;
  priceCalendarRef?: any;
  onMonthChange: (month: string, filters: PriceCalendarFiltersState) => void;
  tableOnly?: boolean;
  loading: boolean;
  disabled?: boolean;
  hasUnsaved?: boolean;
  onDayClick?: () => void;
  useDefault?: boolean;
  viewWithFlightsSearch?: () => void;
  isProductLanding?: boolean;
}

export const PriceCalendarContainer = ({
  display,
  path,
  priceCalendarRef,
  onMonthChange,
  tableOnly,
  loading,
  disabled,
  hasUnsaved,
  useDefault,
  onDayClick,
  viewWithFlightsSearch,
  isProductLanding
}: PriceCalendarContainerProps) => {
  const dispatch = useDispatch();
  const filters: PriceCalendarFiltersState = useSelector(getPriceCalendarFilters);
  const monthly: PriceCalendarMonthlyState = useSelector(getPriceCalendarMonthly);
  const hasOccupancyError: boolean = useSelector(getPriceCalendarOccupancyError);
  const searchType: SearchType = useSelector(getSearchType);
  const isHotelOnly = useSelector(getHotelOnlySearch);
  const [isSearching, setIsSearching] = useState(false);

  const handleOnDayChange = (day: string) => {
    if (!disabled && !loading) {
      dispatch(setDay(day));
      dispatch(resetSelectedFilters(FiltersSideBarType.FLIGHTS));
      if (onDayClick) {
        onDayClick();
      }
    }
  };

  const handleOnSubmit = async () => {
    if (!hasOccupancyError) {
      const response: GetDepAirportsResponse = await new ProductApi().getDepAirports(path);
      const airports: Array<string> = response.data || [];
      const { occupancy, airport, duration } = filters;
      const { date, defaultDate } = monthly;
      const searchDate = useDefault ? date || defaultDate : date;
      setIsSearching(true);
      dispatch(setHotelLandingSearch(!!isProductLanding));
      dispatch(
        performProductSearch({
          ...INITIAL_DEAL_FINDER_STATE,
          destinations: [
            {
              display,
              path,
              airports
            }
          ],
          date: searchDate,
          duration,
          airports: isHotelOnly ? [] : [airport],
          occupancy,
          searchType: searchType,
          preferredBoardBasis: filters.board
        })
      );
    }
  };

  const handleOnMonthChange = (month: string) => {
    onMonthChange(month, filters);
  };

  return (
    <PriceCalendar
      loading={loading || isSearching}
      disabled={disabled}
      priceCalendarRef={priceCalendarRef}
      filters={filters}
      monthly={monthly}
      onDayChange={handleOnDayChange}
      onMonthChange={handleOnMonthChange}
      onSubmit={handleOnSubmit}
      tableOnly={tableOnly}
      hasUnsaved={hasUnsaved}
      useDefault={useDefault}
      viewWithFlightsSearch={viewWithFlightsSearch}
    />
  );
};
