import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import React from 'react';
import { Icons } from '@model/common/icons';
import { Icon } from '@components/common/generic-icon';
import { TestId } from '@components/test-ids';
import { formatAmpersand } from '@util/format-amp';

interface ContainerProps extends ThemeProps {
  hover: boolean;
}
const Container: any = styled.div(({ theme }: ContainerProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.custom.spacing.xSmall}px ${theme.custom.spacing.small}px`,
  border: `1px solid ${theme.custom.colors.group1.base}`,
  backgroundColor: theme.custom.colors.group1.lighter,
  height: 30,
  ...(theme.custom.typography.labels.default as any),
  color: theme.custom.colors.group4.base,
  borderRadius: 4,
  ':hover': {
    border: `1px solid ${theme.custom.colors.group11.base}`,
    backgroundColor: theme.custom.colors.group11.lighter,
    cursor: 'pointer'
  }
}));

const Clear: any = styled(Icon)(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group4.base,
  fontSize: '1rem',
  marginLeft: theme.custom.spacing.xSmall,
  marginTop: 2
}));

export interface PillProps {
  value: any;
  display: string;
  onClear: (payload: any) => void;
  className?: string;
}

export const Pill = ({ display, value, onClear, className }: PillProps) => {
  const handleOnClear = () => onClear(value);
  return (
    <Container className={className} data-testid={TestId.dealFinder.destination.pill} onClick={handleOnClear}>
      {formatAmpersand(display)}
      <Clear name={Icons.CLEAR} />
    </Container>
  );
};
