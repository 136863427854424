import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact } from 'lodash';
import { DealFinderState } from '@model/state';
import { getDealFinderAvailableAirports, getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { setAirports } from '@state/deal-finder/dealFinderOperations';
import { Airports } from './Airports';
import { getAlgoliaTourAirportsByType, getAlgoliaStateAirports } from '@state/algolia/algoliaSelectors';
import { setAirportsQuery } from '@state/algolia/algoliaOperations';
import { TripTypes } from '@model/common/tours/trip-types';
import { AirportResult } from '@model/search';

export interface AirportsContainerProps {
  tabsRef: any;
  isError?: boolean;
  isCondensed: boolean;
  onChange?: (airports: Array<string>) => void;
  onClear?: () => void;
  airports?: Array<string>;
  available?: Array<string>;
  isNotGroupResults?: boolean;
  placeholderKey?: string;
  tripType?: TripTypes;
  isVisible?: boolean;
}

export const AirportsContainer = ({
  tabsRef,
  isError = false,
  isCondensed,
  onChange,
  onClear,
  airports,
  available,
  isNotGroupResults,
  placeholderKey,
  tripType,
  isVisible
}: AirportsContainerProps) => {
  const dispatch = useDispatch();
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const algoliaAirports: Array<AirportResult> = useSelector(
    tripType ? getAlgoliaTourAirportsByType(tripType) : getAlgoliaStateAirports()
  );
  const availableAirports: Array<string> = useSelector(getDealFinderAvailableAirports);

  useEffect(() => {
    if (isVisible) {
      dispatch(setAirportsQuery('', tripType));
    }
  }, [isVisible]);

  const handleOnChange = (payload: Array<string>) => {
    if (onChange) {
      onChange(payload);
    } else {
      dispatch(setAirports(payload));
    }
  };

  const handleOnInputChange = (payload: string) => {
    if (isVisible !== false) {
      dispatch(setAirportsQuery(payload, tripType));
    }
  };

  return (
    <Airports
      values={airports ? compact(airports) : dealFinder.airports}
      isError={isError}
      onChange={handleOnChange}
      onClear={onClear}
      options={algoliaAirports}
      onInputChange={handleOnInputChange}
      available={available || availableAirports}
      tabsRef={tabsRef}
      isCondensed={isCondensed}
      isNotGroupResults={isNotGroupResults}
      placeholderKey={placeholderKey}
    />
  );
};
