import { Option } from '@components/search/airports/list/Option';
import React from 'react';
import { useI18NextContext } from '@components/hooks';
import { uniq } from 'lodash';
import { AirportResult } from '@components/search/airports/Airports';

export interface GroupOptionProps {
  group: AirportResult;
  airports: Array<AirportResult>;
  selected: Array<string>;
  available: Array<string>;
  onChange: (payload: Array<string>) => void;
}

const getIsAllGroupSelected = (options: Array<AirportResult>, selected: Array<string>, available: Array<string>) => {
  const availableOptions = options.filter((option: AirportResult) =>
    available.length ? available.includes(option.airport.code) : true
  );
  const availableSelectedOptions = availableOptions.filter((option: AirportResult) =>
    selected.includes(option.airport.code)
  );
  return !!availableSelectedOptions.length && availableOptions.length === availableSelectedOptions.length;
};

const getIsAllGroupDisabled = (options: Array<AirportResult>, available: Array<string>) => {
  if (!available.length) return false;
  return !options.filter((option: AirportResult) => available.includes(option.airport.code)).length;
};

export const GroupOption = ({ group, airports, selected, available, onChange }: GroupOptionProps) => {
  const t: any = useI18NextContext();
  const isAllGroupSelected: boolean = getIsAllGroupSelected(airports, selected, available);
  const isAllGroupDisabled: boolean = getIsAllGroupDisabled(airports, available);
  const handleOnSelectGroup = () => {
    if (getIsAllGroupSelected(airports, selected, available)) {
      onChange(
        selected.filter((airport: string) => !airports.some((option: AirportResult) => option.airport.code === airport))
      );
    } else {
      onChange(
        uniq(
          airports
            .filter((option: AirportResult) => (available.length ? available.includes(option.airport.code) : true))
            .map((option: AirportResult) => option.airport.code)
            .concat(selected)
        )
      );
    }
  };
  return (
    <Option
      key={group.airport.name}
      id={group.airport.code}
      label={t('search__airports--group-airport', { airport: group.airport.name })}
      onClick={handleOnSelectGroup}
      selected={isAllGroupSelected}
      isDisabled={isAllGroupDisabled}
    />
  );
};
