import styled from '@emotion/styled';
import { Tabs } from '@styles/material-ui';
import React from 'react';
import { Tab } from '@mui/material';
import { DateLabel, TabContent } from './tab-content/TabContent';
import { TabButton } from '@components/price-calendar/nav/tab-button/TabButton';
import { TestId } from '@components/test-ids';
import moment from 'moment';
import { YEAR_MONTH_FORMAT } from '@model/common';
import { ThemeProps } from '@theme/base';

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.group10.lighter,
  display: 'flex'
}));

const TabsStyled = styled(Tabs)(({ theme }: ThemeProps) => ({
  width: '100%',
  ['.MuiTab-root']: {
    minWidth: 100,
    padding: theme.custom.spacing.large,
    [DateLabel]: {
      color: theme.custom.colors.group4.light
    },
    ':hover:not(.Mui-selected)': {
      [DateLabel]: {
        transition: `color ${theme.custom.transitions.default}`,
        color: theme.custom.states.hover.dark
      }
    }
  },
  ['.Mui-selected']: {
    [DateLabel]: {
      color: theme.custom.states.selected.dark
    }
  },
  ['.MuiSvgIcon-root']: {
    width: '4rem',
    height: '4rem',
    color: theme.custom.colors.group4.base
  }
}));

export interface NavProps {
  date: string;
  onChange: (month: string) => void;
  range?: Array<string>;
}

function getMonths(): Array<string> {
  const start = moment();
  const end = moment().add('2', 'year');
  let months: Array<string> = [start.format(YEAR_MONTH_FORMAT)];
  while (start < end) {
    start.add(1, 'month');
    months = [...months, start.format(YEAR_MONTH_FORMAT)];
  }
  return months;
}

export const Nav = ({ date, onChange, range }: NavProps) => {
  const month: string = moment(date).format(YEAR_MONTH_FORMAT);
  const months: Array<string> = range || getMonths();
  return (
    <Container data-testid={TestId.priceCalendar.nav.container}>
      <TabsStyled
        value={date ? month : false}
        onChange={(_: any, newValue: string) => onChange(newValue)}
        scrollButtons
        variant="scrollable"
        ScrollButtonComponent={(props: any) => <TabButton {...props} />}
      >
        {months.map((month: string) => (
          <Tab key={month} value={month} label={<TabContent month={month} />} />
        ))}
      </TabsStyled>
    </Container>
  );
};
