import React, { useState } from 'react';
import { TourHit } from '@model/search/alogolia/tour-hit';
import styled from '@emotion/styled';
import Highlighter from 'react-highlight-words';
import { Checkbox } from '@styles/material-ui';
import { ThemeProps, DisableableThemeProps } from '@theme/base';
import { Icon } from '@components/common/generic-icon';
import { CountryFlag } from '@components/common/country-flag/CountryFlag';
import { countryCodeMapping } from '@util/country-flags';
import { TestId } from '@components/test-ids';
import { escapeRegExp } from '@util/string';

export interface OptionProps {
  option: TourHit;
  input: string;
  isChecked: boolean;
  isDisabled: boolean;
  airports: Array<string>;
  onClick: (payload: TourHit) => void;
  className?: string;
  hasQuery: boolean;
}

const Container: any = styled.div<any>(({ theme }: ThemeProps) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginBottom: theme.custom.spacing.medium,
  '&:last-of-type': {
    marginBottom: theme.custom.spacing.xLarge
  }
}));

const DescriptionContainer = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginRight: theme.custom.spacing.large
}));

const TitleWrapper = styled.div<any>(({ theme, isDisabled }: DisableableThemeProps) => ({
  display: 'inline-block',
  color: isDisabled ? theme.custom.colors.group4.lighter : theme.custom.colors.group4.base,
  strong: {
    color: isDisabled ? theme.custom.colors.group4.lighter : theme.custom.colors.black
  }
}));

const Title = styled<any>(Highlighter)(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  strong: {
    fontWeight: 500
  }
}));

const StyledCheckbox = styled(Checkbox)({
  width: 'auto',
  ['&.MuiCheckbox-root']: {
    padding: 8
  },
  '.MuiFormControlLabel-root': {
    margin: 0,
    width: 'auto'
  }
});

const FlagIcon: any = styled(CountryFlag)({
  display: 'inline-block',
  marginRight: 24
});

const iconStyles = {
  fontSize: 16,
  marginRight: 24
};

export const Option = ({ option, input, onClick, isChecked, isDisabled, className, hasQuery }: OptionProps) => {
  const [hover, setHover] = useState(false);
  // airports.length && option._tags ? !airports.some((airport: string) => option._tags.includes(airport)) : false;
  const isHover: boolean = !isDisabled && hover;
  const handleOnClick = () => {
    if (!isDisabled) {
      onClick(option);
    }
  };

  return (
    <Container
      onClick={handleOnClick}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={className}
      data-testid={TestId.dealFinder.destination.option}
    >
      <DescriptionContainer>
        {hasQuery ? (
          <Icon name={'mhi-map-pin-outline'} css={iconStyles} />
        ) : (
          <FlagIcon countryCode={countryCodeMapping(option.isoCode)} />
        )}
        <TitleWrapper isDisabled={isDisabled}>
          <Title
            searchWords={[escapeRegExp(input)]}
            textToHighlight={hasQuery ? option.name : option.country}
            highlightTag={'strong'}
          />
        </TitleWrapper>
      </DescriptionContainer>
      <StyledCheckbox
        value={option.name}
        hover={isHover}
        checked={isChecked}
        available
        onToggle={() => {}}
        disabled={isDisabled}
      />
    </Container>
  );
};
