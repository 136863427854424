import React from 'react';
import styled from '@emotion/styled';
import { SelectableThemeProps } from '@theme/base';
import { TabIndex } from '@model/common';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';

const Container = styled.div({});

const Heading = styled.div({
  display: 'flex',
  alignItems: 'center'
});

const Tab: any = styled.div(({ theme, isSelected }: SelectableThemeProps) => ({
  flexGrow: 1,
  padding: theme.custom.spacing.small,
  textAlign: 'center',
  backgroundColor: theme.custom.colors.white,
  cursor: 'pointer',
  userSelect: 'none',
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  color: isSelected ? theme.custom.colors.group1.base : theme.custom.colors.group4.base,
  position: 'relative',
  ['&:after']: {
    backgroundColor: theme.custom.colors.group1.base,
    border: `2px solid ${theme.custom.colors.group1.base}`,
    borderRadius: 2,
    bottom: 0,
    content: '""',
    display: isSelected ? 'block' : 'none',
    left: 10,
    position: 'absolute',
    right: 10
  }
}));

export interface TabsProps {
  tab: number;
  onChange: (tab: number) => void;
  children: Array<JSX.Element> | JSX.Element;
}

export const Tabs = ({ tab, onChange, children }: TabsProps) => {
  const t: any = useI18NextContext();
  const singleItem = !Array.isArray(children) || children.length === 1;

  return (
    <Container data-testid={TestId.dealFinder.tabs}>
      <Heading>
        {!singleItem && (
          <>
            <Tab tabIndex={TabIndex.ZERO} isSelected={tab === 0} onClick={() => onChange(0)}>
              {t('search__dates--tab-date')}
            </Tab>
            <Tab tabIndex={TabIndex.ZERO} isSelected={tab === 1} onClick={() => onChange(1)}>
              {t('search__dates--tab-month')}
            </Tab>
          </>
        )}
      </Heading>
      {singleItem && children}
      {!singleItem && children[tab]}
    </Container>
  );
};
