import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { isTablet, mq } from '@styles/breakpoints';
import AutosizeInput from 'react-input-autosize';
import { Pill } from '@components/search/destinations/suggested-destinations/Pill';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';

const SelectionContainer = styled.div(({ theme }: ThemeProps) => ({
  paddingLeft: theme.custom.spacing.medium,
  paddingRight: theme.custom.spacing.medium,
  paddingTop: theme.custom.spacing.medium,
  [mq.medium]: {
    width: 350
  }
}));

const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 0%',
  flexWrap: 'wrap'
});

const StyledInput = styled(AutosizeInput)(({ theme }: ThemeProps) => ({
  input: {
    outline: 'none',
    border: 'none',
    height: 30,
    padding: 0,
    margin: 2,
    ...(theme.custom.typography.paragraph as any),
    '::placeholder': {
      ...(theme.custom.typography.paragraph as any)
    }
  }
}));

const StyledPill = styled(Pill)({
  margin: 2
});

const Separator: any = styled.hr(({ theme }: ThemeProps) => ({
  border: 'none',
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  margin: `${theme.custom.spacing.medium}px -${theme.custom.spacing.medium}px ${theme.custom.spacing.medium}px`,
  marginBottom: 0
}));

export interface HeadingProps {
  input: string;
  values: Array<string>;
  placeholder: string;
  onChange: (payload: string) => void;
  onClear: (payload: Array<string>) => void;
}

export const Heading = ({ input, values, placeholder, onChange, onClear }: HeadingProps) => {
  const ref: any = useRef();
  const t: any = useI18NextContext();
  const inputPlaceholder: string = values.length ? 'search__destination--add-another' : placeholder;
  const handleOnChange = (event: any) => onChange(event.target.value);
  const handleOnClear = (option: string) => onClear(values.filter((value: string) => value !== option));
  const renderValues = values.map((option: string) => (
    <StyledPill key={option} display={option} value={option} onClear={handleOnClear} />
  ));
  useEffect(() => {
    if (!isTablet()) {
      ref.current?.focus();
    }
  }, [values]);
  return (
    <SelectionContainer data-testid={TestId.dealFinder.airports.heading}>
      <InputContainer onClick={() => ref.current?.focus()}>
        {renderValues}
        <StyledInput ref={ref} value={input} onChange={handleOnChange} placeholder={t(inputPlaceholder)} />
      </InputContainer>
      <Separator />
    </SelectionContainer>
  );
};
