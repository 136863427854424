import { Guests } from '@model/search';
import React from 'react';
import { CounterGroup } from './counter-group/CounterGroup';
import { DOBContainer } from './dob-container/DOBContainer';
import { DOB, Occupancy } from '@model/state';

export interface GuestsInputProps {
  occupancy: Occupancy;
  onChange: (guests: Guests) => void;
  canAddGuests: boolean;
  adultsOnly?: boolean;
}

export const GuestsInput = ({ occupancy, onChange, canAddGuests, adultsOnly }: GuestsInputProps) => {
  const { adults, children } = occupancy;
  const handleOnChangeAdults = (adults: number) => onChange({ ...occupancy, adults });
  const handleOnChangeChildren = (children: Array<DOB>) => onChange({ ...occupancy, children });
  return (
    <>
      <CounterGroup
        adults={adults}
        children={children}
        onChangeAdults={handleOnChangeAdults}
        onChangeChildren={handleOnChangeChildren}
        canAddGuests={canAddGuests}
        adultsOnly={adultsOnly}
      />
      {!adultsOnly && children.length > 0 && <DOBContainer children={children} onChange={handleOnChangeChildren} />}
    </>
  );
};
