import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import moment from 'moment';
import { Dictionary, groupBy } from 'lodash';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { Pill } from '@components/search/common/Pill';
import { ISO_8601_DATE_FORMAT, MONTH_FORMAT } from '@model/common';
import { TestId } from '@components/test-ids';
import { monthIsDisabled } from '@model/search';

const Container = styled.div<any>(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'column',
  height: '100%'
}));

const Navigation = styled.div<any>(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: theme.custom.spacing.large
}));

interface StyledChevronProps extends ThemeProps {
  disabled: boolean;
}
const StyledChevron = styled<any>(Icon)(({ theme, disabled }: StyledChevronProps) => ({
  fontSize: '1.4rem',
  cursor: disabled ? 'cursor' : 'pointer',
  color: disabled ? theme.custom.colors.group4.light : theme.custom.colors.group4.base,
  padding: theme.custom.spacing.small,
  margin: -theme.custom.spacing.small,
  ':hover': {
    color: disabled ? theme.custom.colors.group4.light : theme.custom.colors.group7.base
  }
}));

const PillContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%'
});

interface StyledPillProps extends ThemeProps {
  even: boolean;
}
const StyledPill: any = styled<any>(Pill)(({ theme, even }: StyledPillProps) => ({
  marginBottom: theme.custom.spacing.small,
  display: 'inline-box',
  width: `calc(50% - ${theme.custom.spacing.xSmall}px)`,
  marginLeft: even ? 0 : theme.custom.spacing.xSmall,
  marginRight: even ? theme.custom.spacing.xSmall : 0
}));

const Year: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500
}));

export interface MonthsProps {
  month: string | Array<string>;
  onChange: (month: string) => void;
}

export function getMonths(date: moment.Moment): Dictionary<Array<string>> {
  const months: Array<string> = [];
  const dateStart = date.clone().startOf('year');
  const dateEnd = date.clone().add(2, 'year').endOf('year');
  const interim = dateStart.clone();
  while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
    months.push(interim.format(ISO_8601_DATE_FORMAT));
    interim.add(1, 'month');
  }
  return groupBy(months, (month: string) => moment(month).year());
}

export const Months = ({ month, onChange }: MonthsProps) => {
  const months: Dictionary<Array<string>> = getMonths(moment());
  const monthMoment: Array<moment.Moment> = Array.isArray(month)
    ? month.map((m) => moment(m || undefined))
    : [moment(month || undefined)];
  const initialIndex: number = month ? Object.keys(months).indexOf(moment.min(monthMoment).year().toString()) : 0;

  const [index, setIndex] = useState<number>(Math.max(initialIndex, 0));
  const year: string = Object.keys(months)[index];
  const isFirst: boolean = year === Object.keys(months)[0];
  const isLast: boolean = year === Object.keys(months)[Object.keys(months).length - 1];
  const renderPill = (curr: string, index: number) => {
    const currMoment = moment(curr);
    const value: string = currMoment.format(MONTH_FORMAT);
    const selected: boolean = !!monthMoment.find((m) => m.isSame(curr));
    const isDisabled: boolean = monthIsDisabled(curr);

    return (
      <StyledPill
        key={value}
        value={value}
        selected={selected}
        disabled={isDisabled}
        onClick={() => onChange(curr.split('-').slice(0, 2).join('-'))}
        even={index % 2 == 0}
      />
    );
  };

  return (
    <Container>
      <Navigation>
        <StyledChevron name={Icons.CHEVRON_LEFT} disabled={isFirst} onClick={() => setIndex(Math.max(index - 1, 0))} />
        <Year>{year}</Year>
        <StyledChevron name={Icons.CHEVRON_RIGHT} disabled={isLast} onClick={() => setIndex(index + 1)} />
      </Navigation>
      <PillContainer data-testid={TestId.dealFinder.months.pillContainer}>
        {months[Object.keys(months)[index]].map((curr: string, index: number) => renderPill(curr, index))}
      </PillContainer>
    </Container>
  );
};
