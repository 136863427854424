import React from 'react';
import { Destinations } from './Destinations';
import { DealFinderState } from '@model/state';
import { useDispatch, useSelector } from 'react-redux';
import { getDealFinderState, getHasMaximumDestinations } from '@state/deal-finder/dealFinderSelectors';
import {
  addDestination,
  addHotel,
  clearDestinations,
  removeDestination
} from '@state/deal-finder/dealFinderOperations';
import { setGeographyQuery } from '@state/algolia/algoliaOperations';
import { Destination, AlgoliaState } from '@model/state';
import { getAlgoliaState } from '@state/algolia/algoliaSelectors';
import { GeographyResult } from '@model/search';

export interface DestinationContainerProps {
  tabsRef: any;
  isError: boolean;
  isCondensed: boolean;
}

export const DestinationsContainer = ({ tabsRef, isError, isCondensed }: DestinationContainerProps) => {
  const dispatch = useDispatch();
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const algolia: AlgoliaState = useSelector(getAlgoliaState);
  const hasMaximumDestinations: boolean = useSelector(getHasMaximumDestinations);
  const handleOnRemove = (payload: Destination) => dispatch(removeDestination(payload));
  const handleOnAddDestination = (payload: GeographyResult) => dispatch(addDestination(payload));
  const handleOnAddHotel = (payload: GeographyResult) => dispatch(addHotel(payload));
  const handleOnClear = () => dispatch(clearDestinations());
  const handleOnInputChange = (payload: string) => dispatch(setGeographyQuery(payload));
  const { loading, data, query } = algolia.geography;

  return (
    <Destinations
      tabsRef={tabsRef}
      input={query}
      values={dealFinder.destinations}
      isError={isError}
      hasMaximumDestinations={hasMaximumDestinations}
      onAddDestination={handleOnAddDestination}
      onAddHotel={handleOnAddHotel}
      onClear={handleOnClear}
      onRemove={handleOnRemove}
      onInputChange={handleOnInputChange}
      options={data}
      isLoading={loading}
      airports={dealFinder.airports}
      isCondensed={isCondensed}
    />
  );
};
