import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React from 'react';
import { ThemeProps } from '@theme/base';

export interface DividerProps {
  className?: string;
}

export const DividerStyle = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  height: 1,
  backgroundColor: theme.custom.colors.group10.light
}));

export const DividerComponent = ({ className }: DividerProps) => <DividerStyle className={className} />;

export const Divider = withTheme(DividerComponent);
