import moment from 'moment';

export function getCalendarDays(date: moment.Moment) {
  const now = moment();
  const month = isNaN(date.month()) ? now.month() : date.month();
  const year = isNaN(date.year()) ? now.year() : date.year();
  const endDate = moment().date(0).month(month).year(year).endOf('month');
  return Array(endDate.date())
    .fill(0)
    .map((_, i) =>
      moment()
        .year(year)
        .month(month)
        .date(i + 1)
    )
    .map((day) => ({ day, week: day.startOf('isoWeek').week() }))
    .filter(({ week }, i, arr) => arr.findIndex((info) => info.week === week) === i)
    .map(({ day, week }) => ({
      week,
      days: Array(7)
        .fill(0)
        .map((_, i) => moment(day).startOf('isoWeek').week(week).add(i, 'day'))
    }));
}
