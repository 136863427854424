import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import styled from '@emotion/styled';
import { groupBy, Dictionary } from 'lodash';
import { useI18NextContext } from '@components/hooks';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { isMobile, mq } from '@styles/breakpoints';
import { GeographyResult } from '@model/search';
import { Destination } from '@model/state';
import { PlaceType } from '@model/iceberg';
import { Option } from '@components/search/destinations/suggested-destinations/Option';
import { CountryFlag } from '@components/common/country-flag/CountryFlag';
import { TestId } from '@components/test-ids';
import { countryCodeMapping } from '@util/country-flags';

interface ContainerProps extends ThemeProps {
  width: number;
}
const Container: any = styled.div(({ theme, width }: ContainerProps) => ({
  width: '100%',
  padding: theme.custom.spacing.medium,
  paddingBottom: theme.custom.spacing.large,
  [mq.medium]: {
    padding: `${theme.custom.spacing.medium / 2}px ${theme.custom.spacing.medium}px`,
    width: `${width - theme.custom.spacing.small * 2}px`
  },
  [mq.large]: {
    paddingBottom: theme.custom.spacing.xLarge
  }
}));

const SearchableDestinationsDropdown: any = styled.div(({ hasInput }: { hasInput: boolean }) => ({
  display: 'block',
  columnCount: 1,
  margin: 0,
  width: '100%',
  transform: 'translateX(0)',
  breakInside: 'avoid',
  pageBreakInside: 'avoid',
  columnGap: '8rem',
  [mq.small]: {
    columnCount: hasInput ? 1 : 2
  },
  [mq.large]: {
    columnCount: hasInput ? 1 : 3,
    '> div': {
      maxWidth: hasInput ? 400 : 'unset'
    }
  }
}));

const Error: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.xSmall
}));

const Subtitle = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.small,
  marginTop: theme.custom.spacing.small
}));

const OptionRow: any = styled(Option)({
  cursor: 'pointer',
  breakInside: 'avoid',
  pageBreakInside: 'avoid'
});

const CountryList = styled.div(({ theme }: ThemeProps) => ({
  ['&:not(:first-of-type)']: {
    marginTop: theme.custom.spacing.large
  },
  marginBottom: theme.custom.spacing.large,
  breakInside: 'avoid',
  pageBreakInside: 'avoid'
}));

const CountryListTitle = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
  paddingTop: 1,
  breakInside: 'avoid',
  pageBreakInside: 'avoid',
  span: {
    ...(theme.custom.typography.labels.attribute as any),
    fontWeight: 500,
    textTransform: 'uppercase'
  }
}));

const FlagIcon: any = styled(CountryFlag)({
  display: 'inline-block',
  marginRight: 24
});

type CountryList = Dictionary<Array<GeographyResult>>;

export interface DestinationListProps {
  values: Array<Destination>;
  options: Array<GeographyResult>;
  airports: Array<string>;
  tabsRef: any;
  hasMaximumDestinations: boolean;
  onAdd: (payload: GeographyResult) => void;
  onRemove: (payload: Destination) => void;
  input: string;
  onInputChange: (payload: string) => void;
  theme: Theme;
  isLoading: boolean;
}

const NoResults: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.large
}));

const DestinationListComponent = ({
  values,
  options,
  airports,
  hasMaximumDestinations,
  tabsRef,
  onAdd,
  onRemove,
  onInputChange,
  input,
  isLoading
}: DestinationListProps) => {
  const t: any = useI18NextContext();
  const [dropdownWidth, setDropdownWidth] = useState(tabsRef?.current?.offsetWidth || 0);
  const { width } = useWindowSize();

  const handleOnToggle = (payload: GeographyResult) => {
    const value: Destination | undefined = values
      .filter((destination: Destination) => destination.path === payload.name.path)
      .shift();
    if (value) {
      onRemove(value);
    } else {
      onAdd(payload);
    }
    if (input) {
      onInputChange('');
    }
  };

  useEffect(() => {
    const offsetWidth = tabsRef?.current?.offsetWidth;
    if (offsetWidth && !isMobile()) {
      setDropdownWidth(offsetWidth);
    }
  }, [width]);
  const showNoResults: boolean = !isLoading && !options.length;
  const subTitle: string = input ? t('deal-finder__destinations--search-results') : t('deal-finder__destinations--a-z');
  const countryList: CountryList = groupBy(options, 'group');
  const renderableCountryList = Object.entries(countryList).sort();

  const toggleAllIsChecked = (id: string) => {
    const destinations = countryList[id].map((destination) => destination.name.display);
    return destinations.every((destination) => !!values.find((value) => value.display === destination));
  };
  const handleOnToggleAll = ({ id }: GeographyResult) => {
    const destinations = countryList[id];
    const allChecked = toggleAllIsChecked(id);
    destinations.forEach((destination: GeographyResult) => {
      if (allChecked) {
        const selected = values.find((value) => value.display === destination.name.display);
        if (selected) {
          onRemove(selected);
        }
      } else {
        onAdd(destination);
      }
    });
    if (input) {
      onInputChange('');
    }
  };

  const renderOption = (
    result: GeographyResult,
    index: number,
    hideSubtitle?: boolean,
    isChecked?: boolean,
    onClick?: (payload: GeographyResult) => void
  ) => {
    const { name } = result;
    return (
      <OptionRow
        key={name.path.concat(index.toString())}
        option={result}
        airports={airports}
        input={input.replace(/[\(\)\[\]\*\\\?]/g, '')}
        isChecked={isChecked || values.some((destination: Destination) => destination.path === result.name.path)}
        onClick={onClick || handleOnToggle}
        hideSubtitle={hideSubtitle}
      />
    );
  };

  return (
    <Container width={dropdownWidth} data-testid={TestId.dealFinder.search.destinationList}>
      {hasMaximumDestinations && <Error>{t('search__destination--maximum')}</Error>}
      <Subtitle>{subTitle}</Subtitle>
      <SearchableDestinationsDropdown hasInput={!!input}>
        {!!input && options.map((result: GeographyResult, index: number) => renderOption(result, index))}
        {!input &&
          renderableCountryList.map(
            ([name, destinations]: [name: string, destinations: Array<GeographyResult>], index: number) => {
              const isAllChecked = toggleAllIsChecked(name);
              const result = {
                id: name,
                name: {
                  display: t('deal-finder__destinations--all-country', { destination: name }),
                  path: name
                },
                type: PlaceType.COUNTRY
              };
              return (
                <CountryList key={name}>
                  <CountryListTitle>
                    <FlagIcon countryCode={countryCodeMapping(destinations[0].place?.country?.isoCode)} />
                    <span>{name}</span>
                  </CountryListTitle>
                  {destinations.length > 1 && (
                    <div>{renderOption(result as GeographyResult, index, true, isAllChecked, handleOnToggleAll)}</div>
                  )}
                  {destinations.map((result: GeographyResult, index: number) => renderOption(result, index, true))}
                </CountryList>
              );
            }
          )}
        {showNoResults && <NoResults>{t('search__common--no-results')}</NoResults>}
        {isLoading && t('search__common--loading')}
      </SearchableDestinationsDropdown>
    </Container>
  );
};

export const DestinationList = withTheme(DestinationListComponent);
