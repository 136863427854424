import { KeyCodes, TabIndex } from '@model/common';
import React from 'react';
import styled from '@emotion/styled';
import { mq } from '@styles/breakpoints';
import moment from 'moment';
import { ThemeProps, SelectableThemeProps, DisableableThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';
import { dateIsDisabled } from '@model/search';

interface DayContainerProps extends SelectableThemeProps, DisableableThemeProps {}
const DayContainer: any = styled.div(({ theme, isSelected, isDisabled }: DayContainerProps) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: isSelected ? `1px solid ${theme.custom.states.selected.dark}` : '1px solid transparent',
  backgroundColor: isSelected ? theme.custom.colors.group1.lighter : 'transparent',
  cursor: 'pointer',
  borderRadius: 4,
  ':hover': {
    border: isSelected
      ? `1px solid ${theme.custom.states.selected.dark}`
      : `1px solid ${theme.custom.colors.group10.light}`,
    backgroundColor: isSelected ? theme.custom.colors.group1.lighter : theme.custom.colors.group10.lighter,
    ...(isDisabled &&
      ({
        cursor: 'default',
        color: theme.custom.colors.group4.lighter,
        border: '1px solid transparent',
        backgroundColor: 'transparent'
      } as any))
  },
  ...(isDisabled &&
    ({
      cursor: 'default',
      color: theme.custom.colors.group4.lighter,
      border: '1px solid transparent',
      backgroundColor: 'transparent'
    } as any))
}));

const DayTableCell: any = styled.td(({ theme, isSelected }: SelectableThemeProps) => ({
  padding: 0,
  width: '14%',
  position: 'relative',
  textAlign: 'center',
  outline: 'none',
  '&::after': {
    content: '""',
    display: 'block',
    marginTop: '100%'
  },
  '&:focus': {
    [DayContainer]: {
      border: isSelected
        ? `1px solid ${theme.custom.states.selected.dark}`
        : `1px solid ${theme.custom.colors.group7.light}`,
      backgroundColor: isSelected ? theme.custom.colors.group1.lighter : theme.custom.colors.group7.lighter
    }
  },
  div: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Date: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  [mq.medium]: {
    ...(theme.custom.typography.paragraph as any)
  }
}));

export interface DayProps {
  day: moment.Moment;
  month: moment.Moment;
  selected?: moment.Moment;
  onClick: any;
}

export const Day = ({ day, selected, month, onClick }: DayProps) => {
  const handleOnClick = () => {
    if (!isDisabled) {
      onClick(day.toISOString());
    }
  };
  const handleOnKeyDown = (e: any) => {
    if (!isDisabled && e.keyCode === KeyCodes.ENTER) {
      onClick(day.toISOString());
    }
  };
  const isSelected: boolean = selected?.isSame(day) || false;
  const isDayInMonth: boolean = day.isSame(month, 'month');
  const isDisabled: boolean = !isDayInMonth || dateIsDisabled(day);

  if (!isDayInMonth) {
    return <DayTableCell />;
  }
  return (
    <DayTableCell
      tabIndex={!isDisabled ? TabIndex.ZERO : undefined}
      isSelected={isSelected}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      data-testid={TestId.dealFinder.day}
    >
      <DayContainer isSelected={isSelected} isDisabled={isDisabled}>
        <Date>{day.date()}</Date>
      </DayContainer>
    </DayTableCell>
  );
};
