import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compact } from 'lodash';
import { DealFinderState } from '@model/state';
import { getDealFinderState } from '@state/deal-finder/dealFinderSelectors';
import { clearDates, setDate, setFlexibleDays, setMonth } from '@state/deal-finder/dealFinderOperations';
import moment from 'moment';
import { DATE_PICKER_FORMAT, MONTH_YEAR_FORMAT } from '@model/common';
import { Dates } from './Dates';

const getValue = (dealFinder: Partial<DealFinderState>) => {
  if (dealFinder.date) {
    return moment(dealFinder.date).format(DATE_PICKER_FORMAT);
  } else if (dealFinder.month) {
    return moment(dealFinder.month).format(MONTH_YEAR_FORMAT);
  }
  return '';
};

export interface DatesContainerProps {
  tabsRef: any;
  isError?: boolean;
  isCondensed: boolean;
  onMonthChange?: (date: string) => void;
  onClear?: () => void;
  isMonthOnly?: boolean;
  month?: string | Array<string>;
  placeholderKey?: string;
}

export const DatesContainer = ({
  tabsRef,
  isError = false,
  isCondensed,
  onMonthChange,
  onClear,
  isMonthOnly,
  month,
  placeholderKey
}: DatesContainerProps) => {
  const dispatch = useDispatch();
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const value: string | undefined =
    month && Array.isArray(month)
      ? compact(month)
          .map((month) => getValue({ month }))
          .join(', ')
      : getValue(dealFinder);
  const handleOnDateChange = (date: string) => dispatch(setDate(date));
  const handleOnMonthChange = (month: string) => {
    if (onMonthChange) {
      onMonthChange(month);
    } else {
      dispatch(setMonth(month));
    }
  };
  const handleOnFlexibleDaysChange = (checked: boolean) => dispatch(setFlexibleDays(checked));
  const handleOnClear = () => {
    if (onClear) {
      onClear();
    } else {
      dispatch(clearDates());
    }
  };

  return (
    <Dates
      tabsRef={tabsRef}
      isError={isError}
      isCondensed={isCondensed}
      date={isMonthOnly ? '' : dealFinder.date}
      month={month ? compact(month) : dealFinder.month}
      flexibleDays={dealFinder.flexibleDays}
      onFlexibleDaysChange={handleOnFlexibleDaysChange}
      onDateChange={handleOnDateChange}
      onMonthChange={handleOnMonthChange}
      onClear={handleOnClear}
      value={value}
      isMonthOnly={isMonthOnly}
      placeholderKey={placeholderKey}
    />
  );
};
