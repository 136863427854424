import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OccupancyComponent } from './Occupancy';
import { DealFinderState, Occupancy } from '@model/state';
import { getDealFinderState, isNotDefaultOccupancy, getTotalGuests } from '@state/deal-finder/dealFinderSelectors';
import { onOccupancyBlur, onOccupancyClear, setOccupancy } from '@state/deal-finder/dealFinderOperations';

export interface OccupancyContainerProps {
  tabsRef: any;
  isError?: boolean;
  isCondensed: boolean;
  adultsOnly?: boolean;
  maxRooms?: number;
  maxGuestsPerRoom?: number;
  occupancy?: Array<Occupancy>;
  onChange?: (occupancy: Array<Occupancy>) => void;
  onClear?: () => void;
  onBlur?: () => void;
}

export const OccupancyContainer = ({
  tabsRef,
  isError = false,
  isCondensed,
  adultsOnly = false,
  maxRooms,
  occupancy,
  maxGuestsPerRoom,
  onChange,
  onClear,
  onBlur
}: OccupancyContainerProps) => {
  const dispatch = useDispatch();
  const dealFinder: DealFinderState = useSelector(getDealFinderState);
  const occupancyValue = occupancy || dealFinder.occupancy;
  const isClearable: boolean = isNotDefaultOccupancy(occupancyValue);
  const totalGuests: number = getTotalGuests(occupancyValue);
  const handleOnChange = (occupancy: Array<Occupancy>) => {
    if (onChange) {
      onChange(occupancy);
    } else {
      dispatch(setOccupancy(occupancy));
    }
  };
  const handleOnClear = () => {
    if (onClear) {
      onClear();
    } else {
      dispatch(onOccupancyClear());
    }
  };
  const handleOnBlur = () => {
    if (onBlur) {
      onBlur();
    } else {
      dispatch(onOccupancyBlur());
    }
  };

  return (
    <OccupancyComponent
      tabsRef={tabsRef}
      isError={isError}
      isCondensed={isCondensed}
      occupancy={occupancyValue}
      totalGuests={totalGuests}
      isClearable={isClearable}
      adultsOnly={adultsOnly}
      maxGuestsPerRoom={maxGuestsPerRoom}
      onChange={handleOnChange}
      onClear={handleOnClear}
      onBlur={handleOnBlur}
      maxRooms={maxRooms}
    />
  );
};
