import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Occupancy } from '@model/state';
import { DEFAULT_MAX_ROOMS, DEFAULT_MAX_GUESTS } from '@model/common';
import { useI18NextContext } from '@components/hooks';
import { Guests, hasChildrenWithTypoErrors, hasEmptyChildrenDOB } from '@model/search';
import { OccupancyConfiguration } from '@components/search/occupancy/configuration/OccupancyConfiguration';

export interface OccupancyProps {
  occupancy: Array<Occupancy>;
  totalGuests: number;
  isClearable: boolean;
  tabsRef: any;
  onChange: (payload: Array<Occupancy>) => void;
  onClear: () => void;
  onBlur: () => void;
  isError: boolean;
  isCondensed: boolean;
  isDeferred?: boolean;
  adultsOnly?: boolean;
  maxRooms?: number;
  maxGuests?: number;
  maxGuestsPerRoom?: number;
  isDisabled?: boolean;
}

export const getErrorLabel = (occupancy: Array<Occupancy>) => {
  if (hasChildrenWithTypoErrors(occupancy)) {
    return 'search-form__rooms-invalid--error';
  }
  if (hasEmptyChildrenDOB(occupancy)) {
    return 'search-form__rooms-missing--error';
  }
  return 'search-form__rooms-invalid--error';
};

export const OccupancyComponent = ({
  tabsRef,
  occupancy,
  totalGuests,
  onChange,
  onClear,
  isClearable,
  isError,
  onBlur,
  isCondensed,
  isDeferred,
  adultsOnly,
  isDisabled,
  maxRooms = DEFAULT_MAX_ROOMS,
  maxGuests = DEFAULT_MAX_GUESTS,
  maxGuestsPerRoom = 6
}: OccupancyProps) => {
  const t: any = useI18NextContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [updatedOccupancy, setUpdatedOccupancy] = useState(occupancy);
  const title: string = totalGuests > 1 ? 'common__label--guests' : 'search__dob--count_guest';
  const roomsCount: number = updatedOccupancy?.length;
  const disableDecrementRooms = roomsCount === 1;
  const disableAddRooms = roomsCount === maxRooms || totalGuests >= maxGuests;

  useEffect(() => {
    setUpdatedOccupancy(occupancy);
  }, [occupancy]);

  const handleOnRoomCountChange = (count: number) => {
    let newOccupancy = occupancy.slice();
    if (count > roomsCount) {
      newOccupancy = [...updatedOccupancy, { adults: 1, children: [] }];
    } else {
      if (occupancy.length > 1) {
        newOccupancy = updatedOccupancy.slice(0, updatedOccupancy.length - 1);
      }
    }
    setUpdatedOccupancy(newOccupancy);
    if (!isDeferred) {
      onChange(newOccupancy);
    }
  };
  const handleOnChange = (index: number, update: Guests) => {
    const newOccupancy = updatedOccupancy.map((guests: Guests, idx: number) => (index === idx ? update : guests));
    setUpdatedOccupancy(newOccupancy);
    if (!isDeferred) {
      onChange(newOccupancy);
    }
  };
  const handleOnSetIsActive = (payload: boolean) => {
    if (!payload) {
      if (isDeferred && !isEqual(updatedOccupancy, occupancy)) onChange(updatedOccupancy);
      onBlur();
    }
    setIsActive(payload);
  };

  return (
    <SearchContainer
      tabsRef={tabsRef}
      title={'search__occupancy--title'}
      icon={Icons.PERSON}
      isCondensed={isCondensed}
      isClearable={isClearable}
      isError={isError}
      onClear={onClear}
      isActive={isActive}
      isDisabled={isDisabled}
      hasGradient
      onSetActive={handleOnSetIsActive}
      renderValue={() => `${totalGuests} ${t(title)}`}
      renderError={() => (isError ? t(getErrorLabel(updatedOccupancy)) : '')}
      renderBody={() => (
        <OccupancyConfiguration
          occupancy={updatedOccupancy}
          roomCount={roomsCount}
          canAddGuests={totalGuests < maxGuests}
          canAddRooms={maxRooms > 1}
          maxGuestsPerRoom={maxGuestsPerRoom}
          adultsOnly={adultsOnly}
          disableIncrement={disableAddRooms}
          disableDecrement={disableDecrementRooms}
          onChange={handleOnChange}
          onRoomChange={handleOnRoomCountChange}
        />
      )}
    />
  );
};
