import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { DOBChildLabel } from '../dob-row/dob-child-label/DOBChildLabel';
import { Rows } from './rows/Rows';
import { DOB } from '@model/state';
import { ThemeProps } from '@theme/base';

export interface DOBContainerProps {
  children: Array<DOB>;
  onChange: (children: Array<DOB>) => void;
}

const Container = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.custom.spacing.large,
  maxWidth: 300
}));

const InputLabel = styled.div(({ theme }: ThemeProps) => ({
  display: 'flex',
  justifyContent: 'center',
  color: theme.custom.colors.group4.light,
  ...(theme.custom.typography.labels.default as any)
}));

export const updateChildrenOnDateChange = (children: Array<DOB>, index: number, value: DOB) =>
  children.map((child: DOB, idx: number) => (idx === index ? value : child));

export const DOBContainer = (props: DOBContainerProps) => {
  const { children, onChange } = props;
  const t: any = useI18NextContext();
  const onChildValueChange = (value: DOB, index: number) =>
    onChange(updateChildrenOnDateChange(children, index, value));
  return (
    <Container data-testid={TestId.rooms.dobContainer}>
      <DOBChildLabel count={children.length} />
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={2}>
          <InputLabel>{t('common__label--day')}</InputLabel>
        </Grid>
        <Grid item={true} xs={2}>
          <InputLabel>{t('common__label--month')}</InputLabel>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel>{t('common__label--year')}</InputLabel>
        </Grid>
        <Grid item={true} xs={4}>
          <InputLabel>{t('search__dob--input_age')}</InputLabel>
        </Grid>
      </Grid>
      <Rows onChange={onChildValueChange} rows={children} />
    </Container>
  );
};
