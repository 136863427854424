import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { Theme } from '@theme/base';

export const StyledMenuItem = styled(MenuItem)(({ theme, selected }: { theme?: Theme; selected: boolean }) => ({
  '&.MuiMenuItem-root': {
    padding: theme!.custom.spacing.small,
    ...(theme!.custom.typography.labels.default as any),
    color: selected ? theme!.custom.states.selected.dark : theme!.custom.colors.group4.base
  }
}));
