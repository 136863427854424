import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@styles/material-ui';
import { TestId } from '@components/test-ids';
import { DisableableThemeProps } from '@theme/base';

const OptionRow = styled.div<any>(({ theme, isDisabled }: DisableableThemeProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  ...(theme.custom.typography.paragraph as any),
  fontWeight: 500,
  cursor: 'pointer',
  color: isDisabled ? theme.custom.colors.group4.lighter : theme.custom.colors.group4.base,
  '&:last-of-type': {
    paddingBottom: 0
  }
}));

const StyledCheckbox = styled(Checkbox)({
  padding: 9,
  width: 'auto',
  marginRight: 0
});

export interface OptionProps {
  id: string;
  label: string;
  selected: boolean;
  isDisabled: boolean;
  onClick: (payload: string) => void;
}

export const Option = ({ id, label, selected, isDisabled, onClick }: OptionProps) => {
  const [hover, setHover] = useState(false);
  const handleOnClick = () => {
    if (!isDisabled) {
      onClick(id);
    }
  };
  const handleOnToggle = () => {};
  const handleOnMouseOver = () => setHover(true);
  const handleOnMouseOut = () => setHover(false);
  const isHover: boolean = hover && !isDisabled;
  return (
    <OptionRow
      onClick={handleOnClick}
      onMouseOver={handleOnMouseOver}
      onMouseOut={handleOnMouseOut}
      data-testid={TestId.dealFinder.airports.option}
      isDisabled={isDisabled}
    >
      {label}
      <StyledCheckbox
        value={id}
        hover={isHover}
        checked={selected}
        available
        onToggle={handleOnToggle}
        disabled={isDisabled}
      />
    </OptionRow>
  );
};
