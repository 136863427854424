import styled from '@emotion/styled';
import moment from 'moment';
import React, { useState } from 'react';
import { mq } from '@styles/breakpoints';
import { Navigation } from '@components/search/dates/calendar/day-picker/Navigation';
import { Body } from './Body';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import { getDefaultDay } from '@model/search';

const Container = styled.table({
  borderCollapse: 'collapse',
  width: '100%'
});

const WeekdayContainer = styled.thead({});

const WeekdayRow = styled.tr({});

const Weekday = styled.th(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  height: 55,
  fontWeight: 'normal',
  color: theme.custom.colors.group4.light,
  [mq.medium]: {
    ...(theme.custom.typography.paragraph as any)
  }
}));

export interface CalendarProps {
  day?: string;
  onChange: (day: string) => void;
}

export const DayPicker = ({ day, onChange }: CalendarProps) => {
  const defaultDay = getDefaultDay();
  const [month, setMonth] = useState<string>(day || defaultDay.toISOString());
  const monthMoment: moment.Moment = moment(month);

  const handleOnNext = () => {
    setMonth(monthMoment.add(1, 'month').toISOString());
  };

  const handleOnPrev = () => {
    setMonth(monthMoment.subtract(1, 'month').toISOString());
  };

  return (
    <div data-testid={TestId.dealFinder.dayPicker.container}>
      <Navigation month={monthMoment} onPrev={handleOnPrev} onNext={handleOnNext} />
      <Container>
        <WeekdayContainer>
          <WeekdayRow>
            <Weekday>Mon</Weekday>
            <Weekday>Tue</Weekday>
            <Weekday>Wed</Weekday>
            <Weekday>Thu</Weekday>
            <Weekday>Fri</Weekday>
            <Weekday>Sat</Weekday>
            <Weekday>Sun</Weekday>
          </WeekdayRow>
        </WeekdayContainer>
        <Body day={day} month={monthMoment} onChange={onChange} />
      </Container>
    </div>
  );
};
