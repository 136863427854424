import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import styled from '@emotion/styled';
import { useI18NextContext } from '@components/hooks';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps } from '@theme/base';
import { isMobile, mq } from '@styles/breakpoints';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { TestId } from '@components/test-ids';
import { Option } from './TourDestinationOption';

interface ContainerProps extends ThemeProps {
  width: number;
}
const Container: any = styled.div(({ theme, width }: ContainerProps) => ({
  width: '100%',
  padding: theme.custom.spacing.medium,
  paddingBottom: theme.custom.spacing.large,
  [mq.medium]: {
    padding: `${theme.custom.spacing.medium / 2}px ${theme.custom.spacing.medium}px`,
    width: `${width - theme.custom.spacing.small * 2}px`
  },
  [mq.large]: {
    paddingBottom: theme.custom.spacing.xLarge
  }
}));

const SearchableDestinationsDropdown: any = styled.div(
  ({ hasInput, noResults, columnCount }: { hasInput: boolean; noResults: boolean; columnCount?: number }) => ({
    display: 'block',
    columnCount: 1,
    margin: 0,
    width: '100%',
    transform: 'translateX(0)',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    columnGap: '8rem',
    [mq.small]: {
      columnCount: hasInput || noResults ? 1 : 2
    },
    [mq.large]: {
      columnCount: hasInput || noResults ? 1 : columnCount || 3
    }
  })
);

const Subtitle = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.small,
  marginTop: theme.custom.spacing.small
}));

const OptionRow: any = styled(Option)({
  cursor: 'pointer',
  breakInside: 'avoid',
  pageBreakInside: 'avoid'
});

export interface DestinationListProps {
  values: Array<TourHit>;
  options: Array<TourHit>;
  airports: Array<string>;
  tabsRef: any;
  onSelect: (payload: TourHit) => void;
  onRemove: (payload: TourHit) => void;
  input: string;
  onInputChange: (payload: string) => void;
  theme: Theme;
  isLoading: boolean;
  columnCount?: number;
}

const NoResults: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.large
}));

const DestinationListComponent = ({
  values,
  options,
  airports,
  tabsRef,
  onSelect,
  onRemove,
  input,
  isLoading,
  columnCount
}: DestinationListProps) => {
  const t: any = useI18NextContext();
  const [dropdownWidth, setDropdownWidth] = useState(tabsRef?.current?.offsetWidth || 0);
  const { width } = useWindowSize();
  const searchHasQuery: boolean = !!input;

  useEffect(() => {
    const offsetWidth = tabsRef?.current?.offsetWidth;
    if (offsetWidth && !isMobile()) {
      setDropdownWidth(offsetWidth);
    }
  }, [width]);

  const showNoResults: boolean = !isLoading && !options.length;
  const subTitle: string = input ? t('deal-finder__destinations--search-results') : t('deal-finder__destinations--a-z');

  const sortedOptions = [
    ...options.filter((option) => option.objectID === `country_${option.countryId}`),
    ...options.filter((option) => option.objectID !== `country_${option.countryId}`)
  ];

  const searchOptions: Array<TourHit> = searchHasQuery ? sortedOptions : options;

  const renderOption = (result: TourHit) => {
    const hasAirports = !!airports.length;
    const { name, airports: tourAirports = [], objectID, definitionId } = result;
    const isDisabled =
      hasAirports &&
      Array.isArray(tourAirports) &&
      !airports.some((airport: string) => tourAirports.find(({ code }) => code === airport));

    const isChecked = !!values.find((value) => value.name === name);

    return (
      <OptionRow
        key={`${objectID}${definitionId}`}
        option={result}
        airports={airports}
        input={input.replace(/[\(\)\[\]\*\\\?]/g, '')}
        isChecked={isChecked}
        isDisabled={tourAirports.length > 0 && isDisabled}
        onClick={isChecked ? onRemove : onSelect}
        hasQuery={searchHasQuery}
      />
    );
  };

  return (
    <Container width={dropdownWidth} data-testid={TestId.dealFinder.search.toursDestinationList}>
      <Subtitle>{subTitle}</Subtitle>
      <SearchableDestinationsDropdown hasInput={!!input} noResults={showNoResults} columnCount={columnCount}>
        {searchOptions.map((result: TourHit) => renderOption(result))}
        {showNoResults && <NoResults>{t('search__common--no-results')}</NoResults>}
        {isLoading && t('search__common--loading')}
      </SearchableDestinationsDropdown>
    </Container>
  );
};

export const DestinationList = withTheme(DestinationListComponent);
