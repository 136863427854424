import React, { useState } from 'react';
import { OccupancyComponent } from '@components/search/occupancy/Occupancy';
import { getNumberOfGuests } from '@util/common';
import { Occupancy as OccupancyModel } from '@model/state';
import { hasChildrenWithErrors } from '@model/search';
import { isEqual } from 'lodash';

export interface OccupancyProps {
  occupancy: Array<OccupancyModel>;
  onChange: (payload: Array<OccupancyModel>) => void;
  isDeferred?: boolean;
  maxRooms?: number;
  maxGuests?: number;
  adultsOnly?: boolean;
  disabled?: boolean;
}

const INITIAL_OCCUPANCY: Array<OccupancyModel> = [{ adults: 2, children: [] }];

export const Occupancy = ({
  occupancy = [],
  onChange,
  isDeferred,
  maxRooms,
  maxGuests,
  adultsOnly,
  disabled
}: OccupancyProps) => {
  const [error, setError] = useState(false);
  const isClearable: boolean = !isEqual(occupancy, INITIAL_OCCUPANCY);
  const handleOnClear = () => onChange(INITIAL_OCCUPANCY);
  const handleOnBlur = () => setError(hasChildrenWithErrors(occupancy));
  return (
    <OccupancyComponent
      tabsRef={null}
      occupancy={occupancy}
      totalGuests={getNumberOfGuests(occupancy)}
      onChange={onChange}
      onClear={handleOnClear}
      onBlur={handleOnBlur}
      isError={error}
      isClearable={isClearable}
      isCondensed={true}
      isDeferred={isDeferred}
      maxRooms={maxRooms}
      maxGuests={maxGuests}
      adultsOnly={adultsOnly}
      isDisabled={disabled}
    />
  );
};
