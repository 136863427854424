import React, { useState } from 'react';
import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import { useI18NextContext } from '@components/hooks';
import { List } from '@components/search/airports/list/List';
import { Heading } from './Heading';
import { getTitle } from '@components/search/common/get-title';
import { Airport } from '@model/iceberg/service/price-calendar';

export interface AirportsProps {
  values: Array<string>;
  options: Array<AirportResult>;
  available: Array<string>;
  tabsRef: any;
  isError: boolean;
  isCondensed: boolean;
  onInputChange: (payload: string) => void;
  onChange: (payload: Array<string>) => void;
  onClear?: () => void;
  isNotGroupResults?: boolean;
  placeholderKey?: string;
}

export interface AirportResult {
  airport: Airport;
  group: Airport;
}

export const Airports = ({
  values,
  available,
  options,
  isError,
  isCondensed,
  tabsRef,
  onChange,
  onClear,
  onInputChange,
  isNotGroupResults,
  placeholderKey
}: AirportsProps) => {
  const t: any = useI18NextContext();
  const [input, setInput] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleOnChange = (payload: Array<string>) => onChange(payload);
  const handleOnInputChange = (payload: string) => {
    setInput(payload);
    onInputChange(payload);
  };
  const handleOnClear = () => (onClear ? onClear() : onChange([]));

  const renderError = () => <div>{t('search__airport--title')}</div>;
  const renderValue = () => <div>{getTitle(values, placeholderKey || 'search__airports--placeholder', t)}</div>;
  const renderBody = () => (
    <List
      values={values}
      available={available}
      input={input}
      options={options.slice()}
      onChange={handleOnChange}
      isNotGroupResults={isNotGroupResults}
    />
  );
  const renderHeader = () => {
    return isNotGroupResults ? null : (
      <Heading
        input={input}
        values={values}
        onChange={handleOnInputChange}
        onClear={(payload: Array<string>) => onChange(payload)}
        placeholder={'search__airports--enter-airport'}
      />
    );
  };

  return (
    <SearchContainer
      tabsRef={tabsRef}
      title={'search__airports--title'}
      icon={Icons.DEPARTURE}
      isCondensed={isCondensed}
      isClearable={!!values.length}
      isError={isError}
      onClear={handleOnClear}
      isActive={isActive}
      onSetActive={setIsActive}
      renderError={renderError}
      renderValue={renderValue}
      renderBody={renderBody}
      renderHeader={renderHeader}
      hasGradient
    />
  );
};
