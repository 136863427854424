export enum AirportGroup {
  LONDON = 'london',
  SOUTH = 'south',
  SOUTH_WEST = 'south-west',
  MIDLANDS = 'midlands',
  NORTH_EAST = 'north-east',
  NORTH_WEST = 'north-west',
  YORKSHIRE = 'yorkshire',
  SCOTLAND = 'scotland',
  NORTHERN_IRELAND = 'northern-ireland',
  ISLE_OF_MAN = 'isle-of-man'
}

const AIRPORT_GROUP_WEIGHTS: any = {
  [AirportGroup.LONDON]: 1,
  [AirportGroup.SOUTH]: 2,
  [AirportGroup.SOUTH_WEST]: 3,
  [AirportGroup.MIDLANDS]: 4,
  [AirportGroup.NORTH_EAST]: 5,
  [AirportGroup.NORTH_WEST]: 6,
  [AirportGroup.YORKSHIRE]: 7,
  [AirportGroup.SCOTLAND]: 8,
  [AirportGroup.NORTHERN_IRELAND]: 9,
  [AirportGroup.ISLE_OF_MAN]: 10
};

export class AirportSorter {
  public static compare([_, a]: any, [__, b]: any): number {
    const aCode: string | undefined = a[0]?.group?.code;
    const bCode: string | undefined = b[0]?.group?.code;
    if (aCode && !bCode) return -1;
    if (!aCode && bCode) return 1;
    if (aCode && bCode) {
      return AirportSorter.getWeight(aCode) - AirportSorter.getWeight(bCode);
    }
    return 0;
  }

  private static getWeight(code: string) {
    return AIRPORT_GROUP_WEIGHTS[code] || 10;
  }
}
