import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import { TabIndex } from '@model/common';
import React, { ChangeEvent, FC } from 'react';
import { ErrorThemeProps } from '@theme/base';

export interface DOBInputProps {
  minLength: number;
  maxLength: number;
  value: string | undefined;
  onChange: (value: string) => void;
  error: boolean;
  testId?: string;
  onBlur?: (event: any) => void;
}

const NUMBER_REGEX = /^[0-9\b]+$/;

const isValid = (value: string) => !value || NUMBER_REGEX.test(value);

const Input: any = styled.input(({ theme, isError }: ErrorThemeProps) => ({
  border: '1px solid',
  height: '4rem',
  textAlign: 'center',
  width: '100%',
  borderColor: isError ? theme.custom.colors.group11.base : theme.custom.colors.group10.base,
  ...(theme.custom.typography.labels.default as any),
  fontWeight: 'bold',
  ':focus': {
    outline: theme.custom.colors.group7.base,
    borderColor: isError ? theme.custom.colors.group11.base : theme.custom.colors.group7.base
  }
}));

export const DOBInput: FC<DOBInputProps> = (props: DOBInputProps) => {
  const { testId, maxLength, minLength, value, error, onBlur, onChange } = props;
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const update: string = e.target.value;
    if (isValid(e.target.value)) {
      onChange(update);
    }
  };
  return (
    <Input
      data-testid={testId || TestId.dob.input}
      maxLength={maxLength}
      minLength={minLength}
      value={value || ''}
      onChange={handleOnChange}
      isError={error}
      tabIndex={TabIndex.ZERO}
      onKeyDown={onBlur}
    />
  );
};
