import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import React, { useState } from 'react';
import { Pill } from '@components/search/common/Pill';
import { useI18NextContext } from '@components/hooks';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';

const Container = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.medium,
  width: '100%',
  [mq.medium]: {
    height: 'auto'
  }
}));

const PillContainer = styled.div({
  display: 'block'
});

const StyledPill: any = styled(Pill)(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.medium,
  display: 'inline-block',
  width: '32%',
  marginRight: theme.custom.spacing.xSmall,
  '&:nth-of-type(3n)': {
    marginRight: 0
  }
}));

export interface DurationProps {
  duration: number;
  durations: Array<number>;
  onChange: (payload: number) => void;
}

export const Duration = ({ duration = 0, durations, onChange }: DurationProps) => {
  const t: any = useI18NextContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleOnChange = (payload: number) => {
    onChange(payload);
    setIsActive(false);
  };
  return (
    <SearchContainer
      tabsRef={null}
      title={'search__duration--title'}
      icon={Icons.NIGHTS}
      isCondensed
      isClearable={false}
      onClear={() => {}}
      isActive={isActive}
      onSetActive={setIsActive}
      isError={false}
      hideConfirm
      hasGradient={false}
      renderValue={() => `${duration.toString()} ${t('common__label--nights')}`}
      renderBody={() => (
        <Container data-testid={TestId.dealFinder.duration}>
          <PillContainer>
            {durations.map((durationPill: number) => (
              <StyledPill
                key={durationPill}
                selected={durationPill === duration}
                value={`${durationPill.toString()} ${t('common__label--nights')}`}
                onClick={() => handleOnChange(durationPill)}
              />
            ))}
          </PillContainer>
        </Container>
      )}
    />
  );
};
