import { Offer } from '@model/iceberg/service/price-calendar/monthly-response';
import styled from '@emotion/styled';
import moment from 'moment';
import React from 'react';
import { ISO_8601_DATE_FORMAT, YEAR_MONTH_FORMAT } from '@model/common';
import { getCalendarDays } from './get-calendar-days';
import { mq } from '@styles/breakpoints';
import { Day } from './day/Day';
import { TestId } from '@components/test-ids';
import { NoResults } from '@components/price-calendar/no-results/NoResults';
import { ThemeProps } from '@theme/base';

const ScrollWrapper = styled.div({
  overflowX: 'hidden',
  width: '100%',
  position: 'relative'
});

const Container = styled.table({
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  width: '100%'
});

const WeekdayContainer = styled.thead(({ theme }: ThemeProps) => ({
  borderBottom: '1px solid',
  borderColor: theme.custom.colors.group10.light
}));

const WeekdayRow = styled.tr({});

const Weekday = styled.th(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  height: 55,
  fontWeight: 'normal',
  color: theme.custom.colors.group4.light,
  [mq.medium]: {
    ...theme.custom.typography.paragraph
  }
}));

const Body = styled.tbody({
  width: '100%'
});

const Row = styled.tr();

const Mask = styled.div({
  background: `rgba(255,255,255, .7)`,
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%'
});

export interface CalendarProps {
  date: string;
  prices: Array<Offer>;
  onChange: (day: string) => void;
  loading: boolean;
  disabled?: boolean;
  hasUnsaved?: boolean;
  month?: string;
  disableInvalid?: boolean;
  isPrivateTours?: boolean;
}

export const Calendar = ({
  date,
  prices,
  onChange,
  loading,
  disabled,
  hasUnsaved,
  month,
  disableInvalid,
  isPrivateTours
}: CalendarProps) => {
  if (!date) {
    date = moment().format(YEAR_MONTH_FORMAT);
  }
  const selected: moment.Moment = moment(date);

  // used to test whether dates are displayable and selectable in Day component
  const monthOnly = /^\w{4}-\w{2}$/.test(date);
  const middleOfMonth = moment(prices.length ? prices[Math.floor(prices.length / 2)].date : date);
  const baseDate = prices.length ? prices[0].date : date;
  const monthChanged = !moment(month).isSame(date, 'month');

  const renderBody = () =>
    getCalendarDays(moment(baseDate)).map((weekDays: any) => {
      const emptyRow: boolean = weekDays.days.every((day: moment.Moment) => !day.isSame(middleOfMonth, 'month'));
      if (emptyRow) return null;
      return (
        <Row key={weekDays.week}>
          {weekDays.days.map((day: moment.Moment) => {
            return (
              <Day
                key={day.toISOString()}
                day={day}
                middleOfMonth={middleOfMonth}
                onClick={onChange}
                isSelected={!monthOnly && !hasUnsaved && !monthChanged && selected.isSame(day)}
                isLoading={loading}
                offer={prices.find((o: Offer) => moment(o.date, ISO_8601_DATE_FORMAT).isSame(day))}
                disableInvalid={disableInvalid}
                isPrivateTours={isPrivateTours}
              />
            );
          })}
        </Row>
      );
    });
  return (
    <ScrollWrapper data-testid={TestId.priceCalendar.calendar}>
      {!loading && !disabled && !prices.length && <NoResults />}
      <Container>
        <WeekdayContainer>
          <WeekdayRow>
            <Weekday>Mon</Weekday>
            <Weekday>Tue</Weekday>
            <Weekday>Wed</Weekday>
            <Weekday>Thu</Weekday>
            <Weekday>Fri</Weekday>
            <Weekday>Sat</Weekday>
            <Weekday>Sun</Weekday>
          </WeekdayRow>
        </WeekdayContainer>
        <Body>{renderBody()}</Body>
      </Container>
      {(disabled || loading) && <Mask />}
    </ScrollWrapper>
  );
};
