import { Amount, ISO_8601_DATE_FORMAT } from '@model/common';
import { Offer as OfferType } from '@model/iceberg/service/price-calendar/monthly-response';
import moment from 'moment';
import classNames from 'classnames';
import React from 'react';
import { useMedia } from 'react-use';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { Theme, ThemeProps, SelectableThemeProps } from '@theme/base';
import { breakpoints, mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { useI18NextContext } from '@components/hooks';
import { PrimarySpinner as Spinner } from '@components/common/spinner';

export interface DayProps {
  theme?: Theme;
  day: moment.Moment;
  middleOfMonth: moment.Moment;
  isSelected: boolean;
  isLoading: boolean;
  onClick: (date: string) => void;
  offer?: OfferType;
  disableInvalid?: boolean;
  isPrivateTours?: boolean;
}
const Container: any = styled.td({
  height: 60,
  padding: 0,
  [mq.small]: {
    height: 100,
    width: 100
  }
});

const DayContainer: any = styled.div(({ theme, isSelected }: SelectableThemeProps) => ({
  height: '100%',
  width: '100%',
  minWidth: 50,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: isSelected ? `1px solid ${theme.custom.states.selected.dark}` : '1px solid transparent',
  backgroundColor: isSelected ? theme.custom.colors.group1.lighter : 'transparent',
  color: theme.custom.colors.group4.base,
  borderRadius: 4,
  position: 'relative',
  ':not(.selectable)': {
    [Date]: {
      color: theme.custom.colors.group4.lighter
    },
    [Price]: {
      color: theme.custom.colors.group4.lighter
    }
  },
  '&.selectable': {
    cursor: isSelected ? 'default' : 'pointer',
    border: isSelected ? `1px solid ${theme.custom.states.selected.dark}` : '1px solid transparent',
    backgroundColor: isSelected ? theme.custom.colors.group1.lighter : 'transparent',
    i: {
      color: isSelected ? theme.custom.states.selected.dark : theme.custom.colors.group2.base
    },
    ':hover': {
      border: isSelected
        ? `1px solid ${theme.custom.states.selected.dark}`
        : `1px solid ${theme.custom.colors.group10.light}`,
      backgroundColor: isSelected ? theme.custom.colors.group1.lighter : theme.custom.colors.group10.lighter
    }
  }
}));

interface PriceProps extends ThemeProps {
  smaller?: boolean;
}
const Price: any = styled.div(({ theme, smaller }: PriceProps) => ({
  ...(theme.custom.typography.labels.small as any),
  ...(smaller && {
    fontSize: '1rem'
  }),
  letterSpacing: '-0.01071rem',
  minHeight: theme.custom.spacing.large,
  textAlign: 'center',
  whiteSpace: 'pre-line',

  [mq.small]: {
    letterSpacing: 'inherit',
    lineHeight: 'inherit',
    minHeight: theme.custom.spacing.small * 3,
    textAlign: 'left',
    whiteSpace: 'inherit',
    ...(!smaller && {
      ...(theme.custom.typography.labels.hero as any)
    }),
    ...(smaller && {
      fontSize: '1.4rem'
    })
  },
  fontWeight: '500',
  transition: `all ${theme.custom.transitions.smooth}`
}));

const Date: any = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.small as any),
  [mq.small]: {
    ...(theme.custom.typography.paragraph as any)
  }
}));

const SpinnerContainer = styled.div(({ theme }: ThemeProps) => ({
  alignItems: 'center',
  background: theme.custom.colors.group1.lighter,
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  width: '100%'
}));

export const DayComponent = ({
  day,
  isSelected,
  isLoading,
  middleOfMonth,
  offer,
  onClick,
  disableInvalid,
  theme,
  isPrivateTours = false
}: DayProps) => {
  const t: any = useI18NextContext();
  const isMobile = useMedia(`(max-width: ${breakpoints.medium}px)`);
  const dateISO: string = day.format(ISO_8601_DATE_FORMAT);
  const cheapest: boolean = (!isLoading && offer?.cheapest) || false;
  const selectable: boolean =
    day.isSame(middleOfMonth, 'month') &&
    (!!offer || isPrivateTours) &&
    (isPrivateTours || !disableInvalid || !!offer?.price.amount);
  const outOfRange: boolean = !day.isSame(middleOfMonth, 'month');
  const price: string = offer?.price.amount
    ? new Amount(offer?.price?.amount, offer?.price?.currency.code).format()
    : t(
        isPrivateTours
          ? 'common__label--get-a-quote'
          : disableInvalid
            ? 'common__label--na'
            : 'price-calendar__select--label'
      );
  const className: string = classNames({ selectable, cheapest });
  const handleOnChange = () => {
    if ((selectable || isPrivateTours) && !isSelected) {
      onClick(dateISO);
    }
  };
  if (outOfRange) return <Container />;
  if (!outOfRange && !selectable) {
    return (
      <Container data-testid={TestId.priceCalendar.day}>
        <DayContainer isSelected={isSelected} className={className}>
          <Date>{day.date()}</Date>
          <Price>{t('common__label--na')}</Price>
          {isSelected && isLoading && (
            <SpinnerContainer>
              <Spinner color={theme?.custom.colors.group4.base} size={isMobile ? 40 : 50} />
            </SpinnerContainer>
          )}
        </DayContainer>
      </Container>
    );
  }
  const spinnerStyle = { zIndex: 1 };

  return (
    <Container key={dateISO} onClick={handleOnChange} data-testid={TestId.priceCalendar.day}>
      <DayContainer isSelected={isSelected} className={className}>
        <Date>{day.date()}</Date>
        <Price
          smaller={isPrivateTours && (!offer || offer?.soldOut || !offer?.price.amount)}
          data-testid={isSelected ? TestId.priceCalendar.price.selected : TestId.priceCalendar.price.notSelected}
        >
          {offer?.soldOut ? t('tour-product__label--sold-out') : price}
        </Price>
        {isSelected && isLoading && (
          <SpinnerContainer>
            <Spinner color={theme?.custom.colors.group4.base} size={isMobile ? 40 : 50} css={spinnerStyle} />
          </SpinnerContainer>
        )}
      </DayContainer>
    </Container>
  );
};

export const Day = withTheme(DayComponent);
