import { Counter } from '@components/search/occupancy/input/counter-group/counter/Counter';
import React from 'react';
import { GuestsInput } from '@components/search/occupancy/input/GuestsInput';
import { Occupancy } from '@model/state';
import { Guests } from '@model/search';
import { useI18NextContext } from '@components/hooks';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { Divider } from '@components/common/divider';

const Container = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  padding: theme.custom.spacing.medium,
  paddingBottom: theme.custom.spacing.xxLarge,
  [mq.medium]: {
    height: 'auto'
  }
}));

const RoomNumber = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.colors.group4.light,
  fontWeight: 'bold',
  marginBottom: theme.custom.spacing.medium,
  marginTop: theme.custom.spacing.medium,
  textTransform: 'uppercase'
}));

export const DividerStyled = styled(Divider)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.large,
  marginBottom: theme.custom.spacing.large
}));

export interface OccupancyConfigurationProps {
  occupancy: Array<Occupancy>;
  roomCount: number;
  canAddGuests: boolean;
  canAddRooms: boolean;
  maxGuestsPerRoom: number;
  disableIncrement: boolean;
  disableDecrement: boolean;
  className?: string;
  onChange: (index: number, payload: Guests) => void;
  onRoomChange: (count: number) => void;
  adultsOnly?: boolean;
}

export const OccupancyConfiguration = ({
  occupancy,
  roomCount,
  canAddGuests,
  canAddRooms,
  maxGuestsPerRoom,
  disableIncrement,
  disableDecrement,
  onChange,
  onRoomChange,
  className,
  adultsOnly
}: OccupancyConfigurationProps) => {
  const t: any = useI18NextContext();
  return (
    <Container className={className}>
      {canAddRooms && (
        <>
          <Counter
            count={roomCount}
            title={'search__rooms--no-of-rooms'}
            onChange={onRoomChange}
            subtitle={''}
            disableDecrement={disableDecrement}
            disableIncrement={disableIncrement}
          />
          <DividerStyled />
        </>
      )}
      {Object.entries(occupancy).map(([key, value], index: number) => (
        <React.Fragment key={key}>
          {canAddRooms && (
            <RoomNumber>
              {t('search__rooms--room-number')} {index + 1}
            </RoomNumber>
          )}
          <GuestsInput
            key={key}
            occupancy={value}
            onChange={(guests: Occupancy) => onChange(index, guests)}
            canAddGuests={canAddGuests && value.adults + value.children.length < maxGuestsPerRoom}
            adultsOnly={adultsOnly}
          />
          {index < roomCount - 1 && <DividerStyled />}
        </React.Fragment>
      ))}
    </Container>
  );
};
