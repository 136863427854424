import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import AutosizeInput from 'react-input-autosize';
import { Pill } from '@components/search/destinations/suggested-destinations/Pill';
import { isTablet, mq } from '@styles/breakpoints';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { TourHit } from '@model/search/alogolia/tour-hit';
import { isSingleTour } from '@util/tours';

const SelectionContainer: any = styled.div(({ theme }: ThemeProps) => ({
  paddingLeft: theme.custom.spacing.medium,
  paddingRight: theme.custom.spacing.medium,
  paddingTop: theme.custom.spacing.medium,
  [mq.medium]: {
    width: '100%'
  }
}));

const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 0%',
  flexWrap: 'wrap'
});

const StyledInput = styled(AutosizeInput)(({ theme }: ThemeProps) => ({
  input: {
    outline: 'none',
    border: 'none',
    height: 30,
    padding: 0,
    margin: 2,
    ...(theme.custom.typography.paragraph as any),
    '::placeholder': {
      ...(theme.custom.typography.paragraph as any)
    }
  }
}));

const StyledPill = styled(Pill)({
  margin: 2
});

const Separator: any = styled.hr(({ theme }: ThemeProps) => ({
  border: 'none',
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  margin: `${theme.custom.spacing.medium}px -${theme.custom.spacing.medium}px ${theme.custom.spacing.medium}px`,
  marginBottom: 0
}));

interface HeadingProps {
  input: string;
  values: Array<TourHit>;
  onChange: (payload: string) => void;
  onRemove: (payload: TourHit) => void;
  placeholderKey?: string;
}

export const Heading = ({ input, values, onChange, onRemove, placeholderKey }: HeadingProps) => {
  const ref: any = useRef();
  const t: any = useI18NextContext();
  const handleOnChange = (event: any) => onChange(event.target.value);
  const isTourSearch: boolean = values.length === 1 && isSingleTour(values[0]);

  useEffect(() => {
    if (!isTablet()) {
      ref.current?.focus();
    }
  }, [values]);

  return (
    <SelectionContainer data-testid={TestId.tours.search.heading}>
      <InputContainer onClick={() => ref.current?.focus()}>
        {values.map((option) => (
          <StyledPill key={option.name} display={option.name} value={option} onClear={onRemove} />
        ))}
        {!isTourSearch && (
          <StyledInput
            ref={ref}
            value={input}
            onChange={handleOnChange}
            placeholder={t(placeholderKey || 'tours-search__destination--placeholder')}
          />
        )}
      </InputContainer>
      <Separator />
    </SelectionContainer>
  );
};
