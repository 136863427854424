import { Direction } from '@model/common/direction';
import styled from '@emotion/styled';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import React from 'react';
import { ThemeProps } from '@theme/base';

export interface TabButtonProps {
  className: string;
  direction: Direction;
  disabled: boolean;
  onClick: () => void;
}

interface ContainerProps extends ThemeProps {
  disabled: boolean;
}
const Container: any = styled.div(({ theme, disabled }: ContainerProps) => ({
  height: '100%',
  width: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [StyledIcon]: {
    color: disabled ? theme.custom.colors.group10.base : theme.custom.colors.group10.dark,
    padding: theme.custom.spacing.medium
  },
  ':hover': {
    [StyledIcon]: {
      color: disabled ? theme.custom.colors.group10.base : theme.custom.states.hover.dark
    }
  }
}));

const StyledIcon: any = styled(Icon)({
  fontSize: '2rem'
});

export const TabButton = (props: TabButtonProps) => {
  const { direction } = props;
  const name: Icons = direction === Direction.RIGHT ? Icons.CHEVRON_RIGHT : Icons.CHEVRON_LEFT;
  return (
    <Container onClick={props.onClick} disabled={props.disabled}>
      <StyledIcon name={name} disabled={props.disabled} />
    </Container>
  );
};
