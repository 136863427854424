import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import styled from '@emotion/styled';
import React from 'react';
import { DOBRow } from '../../dob-row/DOBRow';
import { Child } from '@model/guests/child';
import { DOB } from '@model/state';
import { ThemeProps } from '@theme/base';

export interface RowsProps {
  rows: Array<DOB>;
  onChange: (value: DOB, index: number) => void;
}

const RowContainer = styled.div(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.xSmall,
  marginBottom: theme.custom.spacing.xSmall
}));

const Error = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.states.error.dark,
  margin: 0,
  marginTop: 10
}));

export const Rows = (props: RowsProps) => {
  const { rows, onChange } = props;
  const t: any = useI18NextContext();
  return (
    <>
      {rows.map((value: DOB, index: number) => (
        <RowContainer key={index} data-testid={TestId.dob.rows}>
          <DOBRow value={value} onChange={(value: DOB) => onChange(value, index)} />
          {new Child(value.day, value.month, value.year).isOverAge && <Error>{t('search__dob--input_error')}</Error>}
        </RowContainer>
      ))}
    </>
  );
};
