import styled from '@emotion/styled';
import React from 'react';
import { getCalendarDays } from '@components/price-calendar';
import moment from 'moment';
import { Day } from '@components/search/dates/calendar/day-picker/Day';
import { ISO_8601_DATE_FORMAT } from '@model/common';
import { TestId } from '@components/test-ids';

const Container = styled.tbody({
  width: '100%'
});

const Row = styled.tr();

export interface BodyProps {
  day?: string;
  month: moment.Moment;
  onChange: (day: string) => void;
}

export const Body = ({ day, month, onChange }: BodyProps) => {
  const selected: moment.Moment | undefined = day ? moment(day) : undefined;
  return (
    <Container data-testid={TestId.dealFinder.dayPicker.body}>
      {getCalendarDays(moment(month)).map((weekDays: any) => {
        const emptyRow: boolean = weekDays.days.every((day: moment.Moment) => !day.isSame(month, 'month'));
        if (emptyRow) return null;
        return (
          <Row key={weekDays.week}>
            {weekDays.days.map((day: moment.Moment) => (
              <Day
                key={day.format(ISO_8601_DATE_FORMAT)}
                day={day}
                month={month}
                selected={selected}
                onClick={onChange}
              />
            ))}
          </Row>
        );
      })}
    </Container>
  );
};
