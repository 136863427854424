import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { mq } from '@styles/breakpoints';
import { ThemeProps } from '@theme/base';
import { TestId } from '@components/test-ids';
import { useI18NextContext } from '@components/hooks';
import { StyledMenuItem } from '@components/price-calendar/filters/styles';
import { Airport } from '@model/iceberg/service/price-calendar';
import { ToolTip } from '@components/common/tooltip';
import { PlainLink } from '@styles/common';
import { ConditionalWrapper } from '@util/common';

const SearchContainerWrapper = styled.div(({ disabled }: { disabled?: boolean }) => ({
  opacity: disabled ? 0.5 : 'unset'
}));

const Container = styled.div({
  width: '100%',
  [mq.medium]: {
    height: 'auto'
  }
});

const ToolTipContent: any = styled.div({
  maxWidth: 250
});

const Link = styled(PlainLink)(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.white,
  cursor: 'pointer',
  textDecoration: 'underline',
  [':hover, :active, :hover:active']: {
    color: theme.custom.colors.white
  }
}));

export interface AirportsProps {
  airport: string;
  airports: Array<Airport>;
  onChange: (payload: string) => void;
  disabled?: boolean;
  viewWithFlightsSearch?: () => void;
}

export const Airports = ({ airport, airports, onChange, disabled, viewWithFlightsSearch }: AirportsProps) => {
  const t = useI18NextContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const handleOnChange = (payload: string) => {
    if (!disabled) {
      setIsActive(false);
      onChange(payload);
    }
  };
  const value: string = airports?.find((item: Airport) => item.code === airport)?.name || '';

  return (
    <SearchContainerWrapper disabled={disabled} data-testid={TestId.priceCalendar.airports.main}>
      <ConditionalWrapper
        condition={!!disabled}
        wrapper={(children) => (
          <ToolTip
            content={
              <ToolTipContent>
                {t('price-calendar__no-flights-selected-tooltip')}
                <Link onClick={viewWithFlightsSearch}>{t('price-calendar__no-flights-selected-link-text')}</Link>
              </ToolTipContent>
            }
            direction={'down'}
            distance={10}
            tipContentHover
          >
            {children}
          </ToolTip>
        )}
      >
        <SearchContainer
          tabsRef={null}
          title={''}
          icon={Icons.FLIGHT}
          isCondensed
          isClearable={false}
          onClear={() => {}}
          isActive={isActive}
          onSetActive={(active: boolean) => {
            if (!disabled) {
              setIsActive(active);
            }
          }}
          isError={false}
          hasGradient={false}
          hideConfirm
          renderValue={() => (disabled ? t('price-calendar__no-flights-selected') : value)}
          renderBody={() => (
            <Container data-testid={TestId.dealFinder.airports.list}>
              {!disabled &&
                airports.map((item: Airport) => (
                  <StyledMenuItem
                    key={item.code}
                    value={item.code}
                    onClick={() => handleOnChange(item.code)}
                    selected={item.code === airport}
                  >
                    <div data-testid={TestId.dealFinder.airports.option}>{item.name}</div>
                  </StyledMenuItem>
                ))}
            </Container>
          )}
        />
      </ConditionalWrapper>
    </SearchContainerWrapper>
  );
};
