import styled from '@emotion/styled';
import React from 'react';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import moment from 'moment';
import { DAY_MONTH_YEAR_FORMAT, ISO_8601_DATE_FORMAT } from '@model/common';
import { Button, ButtonColor } from '@components/material-ui';
import { mq } from '@styles/breakpoints';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';
import { Occupancy } from '@model/state';
import { hasChildrenWithErrors } from '@model/search';

const ArrowIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.6rem',
  color: theme.custom.colors.white
}));

const Container = styled.div(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.large,
  color: theme.custom.colors.group4.light,
  fontWeight: '500',
  ...(theme.custom.typography.paragraph as any),
  borderTop: `1px solid ${theme.custom.colors.group10.light}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  [mq.medium]: {
    padding: theme.custom.spacing.large,
    height: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

const DatesContainer: any = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  flexDirection: 'column',
  [mq.small]: {
    flexDirection: 'row'
  },
  [mq.medium]: {
    justifyContent: 'flex-start'
  }
});

const LabelContainer: any = styled.div(({ theme }: ThemeProps) => ({
  marginRight: theme.custom.spacing.xLarge,
  whiteSpace: 'nowrap'
}));

const Label = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  fontWeight: 500,
  color: theme.custom.colors.group4.base
}));

const StyledIcon = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.4rem',
  marginRight: theme.custom.spacing.small,
  color: theme.custom.colors.group4.light
}));

const RowContainer = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  [mq.medium]: {
    flexDirection: 'row'
  }
});

const StyledButton = styled(Button)(({ theme }: ThemeProps) => ({
  marginTop: theme.custom.spacing.medium,
  width: '100%',
  [mq.medium]: {
    marginTop: 0
  }
}));

export const ErrorLabel = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.states.error.dark,
  marginTop: theme.custom.spacing.xSmall,
  ...(theme.custom.typography.labels.default as any)
}));

export interface FooterProps {
  date: string;
  duration: number;
  hasPrices: boolean;
  occupancy?: Array<Occupancy>;
  onSubmit: () => void;
  buttonLabelKey?: string;
}

export const Footer = ({ date, duration, hasPrices, occupancy, onSubmit, buttonLabelKey }: FooterProps) => {
  const t: any = useI18NextContext();
  const outbound: moment.Moment = moment(date, ISO_8601_DATE_FORMAT);
  const inbound: moment.Moment = moment(date, ISO_8601_DATE_FORMAT).add(duration, 'days');

  return (
    <Container data-testid={TestId.priceCalendar.footer.container}>
      <RowContainer>
        <DatesContainer>
          {outbound.isValid() && (
            <LabelContainer data-testid={TestId.priceCalendar.footer.outbound}>
              <StyledIcon name={Icons.CALENDAR} />
              <Label>
                {t('price-calendar__footer--depart')}{' '}
                {hasPrices ? outbound.format(DAY_MONTH_YEAR_FORMAT) : t('common__label--na')}
              </Label>
            </LabelContainer>
          )}
          {inbound.isValid() && (
            <LabelContainer data-testid={TestId.priceCalendar.footer.inbound}>
              <StyledIcon name={Icons.CALENDAR} />
              <Label>
                {t('price-calendar__footer--return')}{' '}
                {hasPrices ? inbound.format(DAY_MONTH_YEAR_FORMAT) : t('common__label--na')}
              </Label>
            </LabelContainer>
          )}
        </DatesContainer>
        <StyledButton
          label={buttonLabelKey || 'price-calendar__footer--button'}
          color={ButtonColor.SECONDARY}
          onClick={onSubmit}
          disabled={!hasPrices}
          endIcon={<ArrowIcon name={Icons.ARROW_RIGHT} />}
        />
      </RowContainer>
      {occupancy && hasChildrenWithErrors(occupancy) && <ErrorLabel>{t('search__dob--input_error')}</ErrorLabel>}
    </Container>
  );
};
