import { useI18NextContext } from '@components/hooks';
import styled from '@emotion/styled';
import React from 'react';
import { ThemeProps } from '@theme/base';

export interface DOBChildLabelProps {
  count: number;
}

const Container = styled.h4(({ theme }: ThemeProps) => ({
  margin: 0,
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.xSmall
}));

const getChildCountLabel = (count: number) =>
  count === 1 ? 'search__dob--title_child' : 'search__dob--title_children';

export const DOBChildLabel = (props: DOBChildLabelProps) => {
  const { count } = props;
  const t: any = useI18NextContext();
  const label: string = getChildCountLabel(count);
  return <Container>{t(label)}</Container>;
};
