import { SearchContainer } from '@components/search/common/container/SearchContainer';
import { Icons } from '@model/common/icons';
import React, { useEffect, useState } from 'react';
import { useI18NextContext } from '@components/hooks';
import { Heading } from '@components/search/destinations/heading/Heading';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { getTitle } from '@components/search/common/get-title';
import { GeographyResult } from '@model/search';
import { Destination } from '@model/state';
import { DestinationList } from '@components/search/destinations/destination-list/DestinationList';
import { PlaceType } from '@model/iceberg';

interface DestinationsListButtonProps extends ThemeProps {
  condensed: boolean;
}
export const DestinationsListButton: any = styled.div(({ theme, condensed }: DestinationsListButtonProps) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  width: 40,
  height: '100%',
  backgroundColor: theme.custom.colors.group10.lighter,
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  borderLeft: `1px solid ${theme.custom.colors.group10.base}`,
  transition: `box-shadow ${theme.custom.transitions.default}`,
  ['i']: {
    display: 'none',
    [mq.large]: {
      display: 'block',
      color: theme.custom.colors.group4.base,
      fontSize: condensed ? 15 : 20
    }
  }
}));

export const DestinationsListButtonLabel: any = styled.span(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.attribute as any),
  color: theme.custom.colors.group4.base
}));

export interface DestinationProps {
  input: string;
  values: Array<Destination>;
  options: Array<GeographyResult>;
  airports: Array<string>;
  isError: boolean;
  isCondensed: boolean;
  hasMaximumDestinations: boolean;
  tabsRef: any;
  onAddDestination: (payload: GeographyResult) => void;
  onAddHotel: (payload: GeographyResult) => void;
  onRemove: (payload: Destination) => void;
  onClear: () => void;
  onInputChange: (payload: string) => void;
  isLoading: boolean;
}

export const Destinations = ({
  input,
  values,
  airports,
  isError,
  hasMaximumDestinations,
  onAddDestination,
  onAddHotel,
  onRemove,
  onClear,
  onInputChange,
  tabsRef,
  options,
  isCondensed,
  isLoading
}: DestinationProps) => {
  const t: any = useI18NextContext();
  const [isActive, setIsActive] = useState<boolean>(false);
  const titles: Array<string> = values.map((destination: Destination) => destination.display);
  const handleOnAdd = (payload: GeographyResult) => {
    if (payload.type === PlaceType.HOTEL) {
      onAddHotel(payload);
    } else {
      onAddDestination(payload);
    }
  };
  const handleOnInputChange = (payload: string) => onInputChange(payload);
  const handleOnSetIsActive = (payload: boolean) => setIsActive(payload);
  const renderError = () => <div>{t('search__destination--placeholder')}</div>;
  const renderValue = () => <div>{getTitle(titles, 'search__destination--placeholder', t)}</div>;
  const renderBody = () => (
    <DestinationList
      tabsRef={tabsRef}
      values={values}
      options={options}
      airports={airports}
      hasMaximumDestinations={hasMaximumDestinations}
      input={input}
      onInputChange={onInputChange}
      onAdd={handleOnAdd}
      onRemove={onRemove}
      isLoading={isLoading}
    />
  );
  const renderHeader = () => (
    <Heading
      input={input}
      values={values}
      onChange={handleOnInputChange}
      onRemove={onRemove}
      hasMaximumDestinations={hasMaximumDestinations}
    />
  );
  useEffect(() => {
    onInputChange('');
  }, []);
  return (
    <SearchContainer
      tabsRef={tabsRef}
      title={'search__destination--title'}
      icon={Icons.SEARCH}
      isCondensed={isCondensed}
      isClearable={!!values.length}
      isError={isError}
      onClear={onClear}
      onSetActive={handleOnSetIsActive}
      isActive={isActive}
      renderError={renderError}
      renderValue={renderValue}
      renderBody={renderBody}
      renderHeader={renderHeader}
      hasGradient
    />
  );
};
