import { Airport } from '@model/iceberg/service/price-calendar';
import { AirportResult } from '@components/search/airports/Airports';
import React from 'react';
import { groupBy } from 'lodash';
import styled from '@emotion/styled';
import { ThemeProps } from '@theme/base';
import { mq } from '@styles/breakpoints';
import { Option } from './Option';
import { useI18NextContext } from '@components/hooks';
import { TestId } from '@components/test-ids';
import { AirportSorter } from '@components/search/airports/list/airport-sorter';
import { GroupOption } from '@components/search/airports/list/GroupOption';

export interface ListProps {
  values: Array<string>;
  available: Array<string>;
  input: string;
  options: Array<AirportResult>;
  onChange: (payload: Array<string>) => void;
  isNotGroupResults?: boolean;
}

const Container = styled.div(({ theme }: ThemeProps) => ({
  width: '100%',
  height: '100%',
  padding: theme.custom.spacing.medium,
  overflow: 'scroll',
  paddingBottom: theme.custom.spacing.xLarge,
  [mq.medium]: {
    maxHeight: 500,
    height: '100%',
    width: '100%'
  },
  [mq.medium]: {
    width: 350
  }
}));

const GroupTitle = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.medium
}));

const AirportsTitle = styled.div(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.labels.default as any),
  marginBottom: theme.custom.spacing.medium,
  marginTop: theme.custom.spacing.medium
}));

const NoResults: any = styled.div(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group11.base,
  ...(theme.custom.typography.paragraph as any)
}));

export const List = ({ values, available, options, onChange, isNotGroupResults }: ListProps) => {
  const t: any = useI18NextContext();
  const handleOnSelect = (code: string) => {
    if (values.includes(code)) {
      onChange(values.filter((airportCode: string) => airportCode !== code));
    } else {
      onChange(values.concat(code));
    }
  };

  const grouped = groupBy(options, (option: AirportResult) => option.group.name);
  const hasNoResults: boolean = !options.length;
  const hasNoGroupedResults: boolean = !Object.entries(grouped).length;
  return (
    <Container data-testid={TestId.dealFinder.airports.list}>
      {!isNotGroupResults && !hasNoGroupedResults && <GroupTitle>{t('common__label--regions')}</GroupTitle>}
      {!isNotGroupResults &&
        Object.entries(grouped)
          .sort(AirportSorter.compare)
          .map(([key, value]) => {
            const group: Airport = value[0].group;
            const groupAirport: AirportResult = { airport: group, group };
            return (
              <GroupOption
                key={key}
                group={groupAirport}
                airports={value}
                selected={values}
                available={available}
                onChange={onChange}
              />
            );
          })}
      {!hasNoResults && <AirportsTitle>{t('common__label--airports')}</AirportsTitle>}
      {options
        .sort((a: AirportResult, b: AirportResult) => a.airport.name.localeCompare(b.airport.name))
        .map((option: AirportResult) => (
          <Option
            key={option.airport.name}
            id={option.airport.code}
            label={option.airport.name}
            onClick={handleOnSelect}
            selected={values.includes(option.airport.code)}
            isDisabled={!!available.length && !available.includes(option.airport.code)}
          />
        ))}
      {hasNoResults && <NoResults>{t('search__common--no-results')}</NoResults>}
    </Container>
  );
};
