import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { mq } from '@styles/breakpoints';
import { TestId } from '@components/test-ids';
import { ThemeProps } from '@theme/base';

export interface ContentLayoutProps {
  main: any;
  sidebar: any;
  maxWidth?: boolean;
  sidebarMaxHeight?: number;
  sidebarId?: string;
  mainRef?: any;
  noSideBar?: boolean;
}

const Container: any = styled.div(({ theme }: ThemeProps) => ({
  maxWidth: theme.custom.contentSizes.maxContentWidth,
  margin: `0 ${theme.custom.spacing.small}px`,
  [mq.small]: {
    margin: `0 ${theme.custom.spacing.large}px`
  },
  [mq.large]: {
    margin: '0 auto',
    padding: `0 ${theme.custom.spacing.large}px`
  }
}));

const GridContainer = styled(Grid)({
  marginTop: 0,
  marginBottom: 0
});

const GridItem = styled(Grid)({
  ['&.MuiGrid-item']: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

export const Sidebar = styled.aside(({ maxHeight }: any) => ({
  height: '100%',
  maxHeight: maxHeight ? maxHeight : '100%'
}));

export const ContentLayout = ({
  maxWidth,
  main,
  sidebar,
  sidebarMaxHeight,
  sidebarId,
  noSideBar,
  mainRef
}: ContentLayoutProps) => {
  return (
    <Container maxWidth={maxWidth} data-testid={TestId.contentLayout}>
      <GridContainer container spacing={4}>
        <GridItem item xs={12} sm={12} md={!noSideBar ? 8 : 12} ref={mainRef}>
          {main}
        </GridItem>
        {!noSideBar && (
          <GridItem item xs={12} sm={12} md={4}>
            <Sidebar maxHeight={sidebarMaxHeight} id={sidebarId}>
              {sidebar}
            </Sidebar>
          </GridItem>
        )}
      </GridContainer>
    </Container>
  );
};
